<template>
    <div>
        <MeasurementItem
            v-if="d1"
            :title="d1.title"
            :datetime="d1.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d1.key,
                    labelPair: 'circuit',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="d1.value ? 'closed' : 'open'"
                />
            </template>

            <template #content>
                <h3 class="no-padding-left">
                    {{ $t(d1.value ? 'd1Closed' : 'd1Open') }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="t1"
            :title="t1.title"
            :datetime="t1.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
                query: { assetType },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t1') }}:

                <h3 class="sensor-data-temperature">
                    {{ t1.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
                query: { assetType },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
                query: { assetType },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidityAbsolute"
            :title="humidityAbsolute.title"
            :datetime="humidityAbsolute.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity_absolute') }}:

                <h3 class="sensor-data-gpm3">
                    {{ humidityAbsolute.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="externalHumidity"
            :title="externalHumidity.title"
            :datetime="externalHumidity.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
                query: { assetType },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('externalHumidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ externalHumidity.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import { measurementHelper } from '@/utils'
import DropIcon from './icons/DropIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'
import { calculateHumidity } from '@/components/asset-types/cm2-humidity/mixins/humidityCalculation'

export default {
    name: 'CM2HumidityMeasurement',
    components: {
        DropIcon,
        FilledCircleIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        assetType() {
            const assetType = this.tracker.asset_details.asset_type_type
            return assetType === 'cm2-rope' ? assetType : undefined
        },
        d1() {
            return this.getMeasurementItem('d1')
        },
        externalHumidity() {
            const item = this.getMeasurementItem('ai1')
            item.key = 'external_humidity'
            item.value = calculateHumidity(
                item.value,
                this.tracker.asset_details.asset_type_type
            )
            return item
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        humidityAbsolute() {
            if (!this.humidity || !this.temperature) {
                return null
            }

            const key = `${this.humidity.key} | ${this.temperature.key}`
            const lastUpdated = [
                this.humidity.lastUpdated,
                this.temperature.lastUpdated,
            ].sort()[1]

            return {
                key,
                title: this.getItemTitle(
                    this.$t('shared.measurements.humidity_absolute'),
                    lastUpdated
                ),
                value: measurementHelper.calculateAbsoluteHumidity(
                    this.humidity.value,
                    this.temperature.value
                ),
                lastUpdated,
            }
        },
        t1() {
            return this.getMeasurementItem('t1')
        },
        temperature() {
            const item = this.getMeasurementItem('temperature')
            return item?.value === 300 ? null : item
        },
    },
}
</script>

<i18n>
{
    "en": {
        "d1Closed": "DI Closed",
        "d1Open": "DI Open",
        "externalHumidity": "External moisture",
        "humidity": "Internal humidity relative",
        "humidity_absolute": "Internal humidity absolute",
        "t1": "External temperature",
        "temperature": "Internal temperature"
    },
    "de": {
        "d1Closed": "DI Geschlossen",
        "d1Open": "DI Offen",
        "externalHumidity": "Externe Feuchtigkeit",
        "humidity": "Interne relative Luftfeuchtigkeit",
        "humidity_absolute": "Interne absolute Luftfeuchtigkeit",
        "t1": "Externe Temperatur",
        "temperature": "Interne Temperatur"
    },
    "fr": {
        "d1Closed": "DI Fermé",
        "d1Open": "DI Ouvert",
        "externalHumidity": "Humidité externe",
        "humidity": "Humidité interne relative",
        "humidity_absolute": "Humidité interne absolue",
        "t1": "Température externe",
        "temperature": "Température interne"
    },
    "it": {
        "d1Closed": "DI Chiuso",
        "d1Open": "DI Aperto",
        "externalHumidity": "Umidità esterna",
        "humidity": "Umidità interna relativa",
        "humidity_absolute": "Umidità interna assoluta",
        "t1": "Temperatura esterna",
        "temperature": "Temperatura interna"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-item__content .no-padding-left {
    padding-left: 0;
}
.open {
    color: $color-red;
}

.closed {
    color: $color-green;
}
</style>
