<template>
    <div>
        <SaltSpreadingMeasurementFragment v-bind="$props" />

        <MachineMeasurement v-bind="$props" />
    </div>
</template>

<script>
import MachineMeasurement from './MachineMeasurement'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import SaltSpreadingMeasurementFragment from './SaltSpreadingMeasurementFragment'

export default {
    name: 'SaltMeasurement',
    components: {
        MachineMeasurement,
        SaltSpreadingMeasurementFragment,
    },
    mixins: [MeasurementListHelper],
}
</script>
