<template>
    <div>
        <MeasurementItem
            v-if="batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: batteryVoltage.key,
                },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery_voltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="fenceVoltage"
            :title="fenceVoltage.title"
            :datetime="fenceVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: fenceVoltage.key,
                },
                query: {
                    assetType: 'fency-boy',
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('fenceVoltage') }}:

                <h3 class="sensor-data-voltage-k">
                    {{ fenceVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: temperature.key,
                },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: humidity.key,
                },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="impulses"
            :title="impulses.title"
            :datetime="impulses.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: impulses.key,
                },
                query: {
                    assetType: 'fency-boy',
                },
            }"
        >
            <template #icon>
                <LineChartIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('impulses') }}:

                <h3>
                    {{ impulses.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import DropIcon from './icons/DropIcon'
import FlashIcon from './icons/FlashIcon'
import LineChartIcon from './icons/LineChartIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'FencyBoyMeasurement',
    components: {
        BatteryIcon,
        DropIcon,
        FlashIcon,
        LineChartIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        fenceVoltage() {
            const data = this.getMeasurementItem('voltage_0')
            if (typeof data.value === 'number') {
                data.value = (data.value / 1000).toFixed(2)
            }
            return data
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        impulses() {
            return this.getMeasurementItem('d1_count')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "fenceVoltage": "Fence voltage",
        "impulses": "Impulses"
    },
    "de": {
        "fenceVoltage": "Spannung Weidezaun",
        "impulses": "Impulse"
    },
    "fr": {
        "fenceVoltage": "Tension de la clôture",
        "impulses": "Impulsions"
    },
    "it": {
        "fenceVoltage": "Voltaggio Recinzione",
        "impulses": "Impulsi"
    }
}
</i18n>
