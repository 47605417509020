<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="0.75" y1="21.75" x2="23.25" y2="21.75" />

        <path
            class="a"
            d="M6,11.25H3a.75.75,0,0,0-.75.75v9.75h4.5V12A.75.75,0,0,0,6,11.25Z"
        />

        <path
            class="a"
            d="M13.5,2.25h-3A.75.75,0,0,0,9.75,3V21.75h4.5V3A.75.75,0,0,0,13.5,2.25Z"
        />

        <path
            class="a"
            d="M21,6.75H18a.75.75,0,0,0-.75.75V21.75h4.5V7.5A.75.75,0,0,0,21,6.75Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'BarChartIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
