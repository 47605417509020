<template>
    <div>
        <MeasurementItem
            v-if="d1"
            :title="d1.title"
            :datetime="d1.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: { id: tracker.id, dataType: d1.key, labelPair: 'door' },
            }"
        >
            <template #icon>
                <ElectronicsSwitchIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('d1') }}:

                <h3>
                    {{ $t(d1.value ? 'open' : 'closed') }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="d2"
            :title="d2.title"
            :datetime="d2.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: { id: tracker.id, dataType: d2.key, labelPair: 'door' },
            }"
        >
            <template #icon>
                <ElectronicsSwitchIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('d2') }}:

                <h3>
                    {{ $t(d2.value ? 'open' : 'closed') }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import ElectronicsSwitchIcon from './icons/ElectronicsSwitchIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'DigitalInputMeasurement',
    components: {
        ElectronicsSwitchIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        d1() {
            return this.getMeasurementItem('d1')
        },
        d2() {
            return this.getMeasurementItem('d2')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "closed": "Closed",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "open": "Open"
    },
    "de": {
        "closed": "Geschlossen",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "open": "Offen"
    },
    "fr": {
        "closed": "Fermé",
        "d1": "Canal 1",
        "d2": "Canal 2",
        "open": "Ouvert"
    },
    "it": {
        "closed": "Closed",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "open": "Open"
    }
}
</i18n>
