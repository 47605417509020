<template>
    <div>
        <MeasurementItem
            v-if="precipitationAbsolute"
            :title="precipitationAbsolute.title"
            :datetime="precipitationAbsolute.lastUpdated"
            :link-to="
                precipitationIntensity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [
                                  precipitationAbsolute.key,
                                  precipitationIntensity.key,
                              ],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: precipitationAbsolute.key,
                          },
                      }
            "
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.precipitation_absolute') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ precipitationAbsolute.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="precipitationIntensity"
            :title="precipitationIntensity.title"
            :datetime="precipitationIntensity.lastUpdated"
            :link-to="
                precipitationAbsolute
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [
                                  precipitationAbsolute.key,
                                  precipitationIntensity.key,
                              ],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: precipitationIntensity.key,
                          },
                      }
            "
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.precipitation_intensity') }}:

                <h3 class="sensor-data-speed-mm">
                    {{ precipitationIntensity.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'Ws100Measurement',
    components: {
        DropIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        precipitationAbsolute() {
            return this.getMeasurementItem('precipitation_absolute')
        },
        precipitationIntensity() {
            return this.getMeasurementItem('precipitation_intensity')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "off": "Off",
        "on": "On"
    },
    "de": {
        "off": "Aus",
        "on": "Ein"
    },
    "fr": {
        "off": "Off",
        "on": "On"
    },
    "it": {
        "off": "Off",
        "on": "On"
    }
}
</i18n>
