<template>
    <div>
        <MeasurementItem
            v-if="t1"
            :title="t1.title"
            :datetime="t1.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('t1') }}:

                <h3 class="sensor-data-temperature">
                    {{ t1.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidityAbsolute"
            :title="humidityAbsolute.title"
            :datetime="humidityAbsolute.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('humidity_absolute') }}:

                <h3 class="sensor-data-gpm3">
                    {{ humidityAbsolute.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="externalHumidity"
            :title="externalHumidity.title"
            :datetime="externalHumidity.lastUpdated"
            :link-to="{
                name: 'cm2humiditychart',
                params: { id: tracker.id },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('externalHumidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ externalHumidity.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import { measurementHelper } from '@/utils'
import { calculateHumidity } from '@/components/asset-types/cm2-humidity/mixins/humidityCalculation'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'CM2SimpleMeasurement',
    components: {
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        externalHumidity() {
            const item = this.getMeasurementItem('ai1')
            item.key = 'external_humidity'
            item.value = calculateHumidity(
                item.value,
                this.tracker.asset_details.asset_type_type
            )
            return item
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        humidityAbsolute() {
            if (!this.humidity || !this.temperature) {
                return null
            }

            const key = `${this.humidity.key} | ${this.temperature.key}`
            const lastUpdated = [
                this.humidity.lastUpdated,
                this.temperature.lastUpdated,
            ].sort()[1]

            return {
                key,
                title: this.getItemTitle(
                    this.$t('shared.measurements.humidity_absolute'),
                    lastUpdated
                ),
                value: measurementHelper.calculateAbsoluteHumidity(
                    this.humidity.value,
                    this.temperature.value
                ),
                lastUpdated,
            }
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        t1() {
            return this.getMeasurementItem('t1')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "externalHumidity": "External humidity",
        "humidity": "Internal humidity relative",
        "humidity_absolute": "Internal humidity absolute",
        "t1": "External temperature",
        "temperature": "Internal temperature"
    },
    "de": {
        "externalHumidity": "Externe Feuchtigkeit",
        "humidity": "Interne Feuchtigkeit relative",
        "humidity_absolute": "Interne Luftfeuchtigkeit absolut",
        "t1": "Externe Temperatur",
        "temperature": "Interne Temperatur"
    },
    "fr": {
        "externalHumidity": "Humidité externe",
        "humidity": "Humidité interne relative",
        "humidity_absolute": "Humidité interne absolue",
        "t1": "Température externe",
        "temperature": "Température interne"
    },
    "it": {
        "externalHumidity": "Umidità esterna",
        "humidity": "Umidità interna relativa",
        "humidity_absolute": "Umidità interna assoluta",
        "t1": "Temperatura esterna",
        "temperature": "Temperatura interna"
    }
}
</i18n>
