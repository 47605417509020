var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.voltage0)?_c('MeasurementItem',{attrs:{"title":_vm.voltage0.title,"datetime":_vm.voltage0.lastUpdated,"link-to":_vm.voltage0.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('router.voltage_0'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.voltage0.value)+" ")])]},proxy:true}],null,false,1331323512)}):_vm._e(),(_vm.voltage1)?_c('MeasurementItem',{attrs:{"title":_vm.voltage1.title,"datetime":_vm.voltage1.lastUpdated,"link-to":_vm.voltage1.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('router.voltage_1'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.voltage1.value)+" ")])]},proxy:true}],null,false,739818200)}):_vm._e(),(_vm.d1)?_c('MeasurementItem',{attrs:{"title":_vm.d1.title,"datetime":_vm.d1.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d1.key,
                labelPair: 'operation',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d1.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.d1'))+": "),_c('h3',[_vm._v(_vm._s(_vm.d1.value ? _vm.$t('on') : _vm.$t('off')))])]},proxy:true}],null,false,1601139922)}):_vm._e(),(_vm.d2)?_c('MeasurementItem',{attrs:{"title":_vm.d2.title,"datetime":_vm.d2.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d2.key,
                labelPair: 'operation',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d2.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.d2'))+": "),_c('h3',[_vm._v(_vm._s(_vm.d2.value ? _vm.$t('on') : _vm.$t('off')))])]},proxy:true}],null,false,3557398897)}):_vm._e(),(_vm.d3)?_c('MeasurementItem',{attrs:{"title":_vm.d3.title,"datetime":_vm.d3.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d3.key,
                labelPair: 'operation',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d3.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.d3'))+": "),_c('h3',[_vm._v(_vm._s(_vm.d3.value ? _vm.$t('on') : _vm.$t('off')))])]},proxy:true}],null,false,2315574544)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }