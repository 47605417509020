<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="''"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M4.917,19.499H0.75c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h2.637c-1.262-1.813-1.924-3.994-1.875-6.22
		C1.573,8.974,2.723,6.36,4.749,4.421c1.964-1.88,4.544-2.916,7.265-2.916c1.388,0,2.743,0.271,4.027,0.804
		c0.185,0.077,0.329,0.221,0.406,0.406c0.076,0.185,0.076,0.389-0.001,0.574c-0.117,0.281-0.389,0.462-0.693,0.462
		c-0.099,0-0.196-0.019-0.288-0.058c-1.1-0.457-2.261-0.689-3.451-0.689c-2.332,0-4.544,0.888-6.228,2.5
		c-1.737,1.663-2.723,3.903-2.775,6.307c-0.04,1.844,0.483,3.64,1.489,5.158v-2.721c0-0.414,0.336-0.75,0.75-0.75S6,13.835,6,14.249
		v4.5c0,0.414-0.336,0.75-0.75,0.75H5.012c-0.016,0.002-0.031,0.003-0.045,0.003C4.955,19.502,4.934,19.5,4.917,19.499z"
        />
        <path
            d="M11.995,22.501c-1.392,0-2.75-0.272-4.038-0.808c-0.185-0.077-0.329-0.221-0.405-0.407
		c-0.076-0.185-0.076-0.389,0.001-0.574c0.117-0.281,0.389-0.462,0.693-0.462c0.099,0,0.195,0.019,0.287,0.057
		c1.105,0.46,2.269,0.694,3.462,0.694c0,0,0,0,0,0c2.336,0,4.55-0.891,6.234-2.508c1.735-1.665,2.718-3.906,2.767-6.31
		c0.038-1.845-0.488-3.64-1.497-5.158v2.724c0,0.414-0.336,0.75-0.75,0.75S18,10.162,18,9.749v-4.5c0-0.414,0.336-0.75,0.75-0.75
		h4.5c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75h-2.635c1.263,1.811,1.927,3.99,1.882,6.215
		c-0.058,2.804-1.204,5.419-3.228,7.362C17.303,21.462,14.72,22.501,11.995,22.501L11.995,22.501z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'StopwatchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
line {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
