var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.fillLevel)?_c('MeasurementItem',{attrs:{"title":_vm.fillLevel.title,"datetime":_vm.fillLevel.lastUpdated,"link-to":_vm.fillLevel.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BinFillIcon',{attrs:{"width":"24","height":"24","fullness":_vm.fillLevel.value,"fill-color":_vm.fillLevel.value >= 20 ? '#41b883' : '#d32f2f'}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.fill_level'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.fillLevel.value)+" ")])]},proxy:true}],null,false,1635583565)}):_vm._e(),(_vm.mass)?_c('MeasurementItem',{attrs:{"title":_vm.mass.title,"datetime":_vm.mass.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.mass.key,
            },
            query: {
                assetType: 'silo',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ScalesIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.mass'))+": "),_c('h3',[_vm._v(_vm._s(_vm.mass.value)+" t")])]},proxy:true}],null,false,165377268)}):_vm._e(),(_vm.volume)?_c('MeasurementItem',{attrs:{"title":_vm.volume.title,"datetime":_vm.volume.lastUpdated,"link-to":_vm.volume.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('CylinderIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.volume'))+": "),_c('h3',[_vm._v(_vm._s(_vm.volume.value)+" m³")])]},proxy:true}],null,false,3250440023)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }