<template>
    <div>
        <MeasurementItem
            v-if="voltage0"
            :title="voltage0.title"
            :datetime="voltage0.lastUpdated"
            :link-to="voltage0.link"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('router.voltage_0') }}:

                <h3 class="sensor-data-voltage">
                    {{ voltage0.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="voltage1"
            :title="voltage1.title"
            :datetime="voltage1.lastUpdated"
            :link-to="voltage1.link"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('router.voltage_1') }}:

                <h3 class="sensor-data-voltage">
                    {{ voltage1.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="d1"
            :title="d1.title"
            :datetime="d1.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d1.key,
                    labelPair: 'operation',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="d1.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.d1') }}:

                <h3>{{ d1.value ? $t('on') : $t('off') }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="d2"
            :title="d2.title"
            :datetime="d2.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d2.key,
                    labelPair: 'operation',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="d2.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.d2') }}:

                <h3>{{ d2.value ? $t('on') : $t('off') }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="d3"
            :title="d3.title"
            :datetime="d3.lastUpdated"
            :link-to="{
                name: 'statuschartview',
                params: {
                    id: tracker.id,
                    dataType: d3.key,
                    labelPair: 'operation',
                },
            }"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="d3.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.d3') }}:

                <h3>{{ d3.value ? $t('on') : $t('off') }}</h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import FilledCircleIcon from './icons/FilledCircleIcon'
import FlashIcon from './icons/FlashIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'Dragino22222Measurement',
    components: {
        FilledCircleIcon,
        FlashIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        d1() {
            return this.getMeasurementItem('d1')
        },
        d2() {
            return this.getMeasurementItem('d2')
        },
        d3() {
            return this.getMeasurementItem('d3')
        },
        voltage0() {
            const data = this.getMeasurementItem('voltage_0')
            data.link.query = {
                assetType: 'dragino',
            }
            return data
        },
        voltage1() {
            const data = this.getMeasurementItem('voltage_1')
            data.link.query = {
                assetType: 'dragino',
            }
            return data
        },
    },
}
</script>

<i18n>
{
    "en": {
        "off": "Off",
        "on": "On"
    },
    "de": {
        "off": "Aus",
        "on": "Ein"
    },
    "fr": {
        "off": "Off",
        "on": "On"
    },
    "it": {
        "off": "Off",
        "on": "On"
    }
}
</i18n>

<style lang="scss" scoped>
.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
