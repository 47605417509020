<template>
    <div>
        <MeasurementItem
            v-if="batteryPercentage"
            :title="batteryPercentage.title"
            :datetime="batteryPercentage.lastUpdated"
            :link-to="batteryPercentage.link"
        >
            <template #icon>
                <BatteryIcon
                    :battery-level-in-percent="batteryPercentage.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('batteryPercentage') }}:

                <h3 class="sensor-data-percentage">
                    {{ batteryPercentage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-else-if="batteryStatus"
            :title="batteryStatus.title"
            :datetime="batteryStatus.lastUpdated"
            :link-to="
                batteryVoltage
                    ? {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: batteryVoltage.key,
                          },
                          query: {
                              assetType: 'ecocoach',
                          },
                      }
                    : null
            "
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-status="batteryStatus.value"
                />
            </template>

            <template #content>
                {{ $t('battery') }}:

                <h3>
                    {{
                        $t(
                            'shared.measurementOptions.battery_status.' +
                                batteryStatus.value
                        )
                    }}

                    <template v-if="batteryVoltage">
                        ({{ batteryVoltage.value }} V)
                    </template>
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-else-if="!batteryPercentage && batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="batteryVoltage.link"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('batteryVoltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: temperature.key,
                },
                query: {
                    assetType: 'ecocoach',
                },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperatureAfe"
            :title="temperatureAfe.title"
            :datetime="temperatureAfe.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: temperatureAfe.key,
                },
                query: {
                    assetType: 'ecocoach',
                },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('temperatureAfe') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperatureAfe.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="runningTime"
            :title="runningTime.title"
            :datetime="runningTime.lastUpdated"
        >
            <template #icon>
                <StopwatchIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('runningTime') }}:

                <h3>
                    {{ runningTime.textDuration }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="energyRemaining"
            :title="energyRemaining.title"
            :datetime="energyRemaining.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: energyRemaining.key,
                },
                query: {
                    assetType: 'ecocoach',
                },
            }"
        >
            <template #icon>
                <EnergyIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('energyRemaining') }}:

                <h3 class="sensor-data-energy">
                    {{ energyRemaining.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="powerUpCounter"
            :title="powerUpCounter.title"
            :datetime="powerUpCounter.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: powerUpCounter.key,
                },
                query: {
                    assetType: 'ecocoach',
                },
            }"
        >
            <template #icon>
                <LineChartIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('powerUpCounter') }}:

                <h3>
                    {{ powerUpCounter.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import { formatHelper } from '../utils'
import BatteryIcon from './icons/BatteryIcon'
import EnergyIcon from './icons/EnergyIcon'
import FlashIcon from './icons/FlashIcon'
import LineChartIcon from './icons/LineChartIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import StopwatchIcon from './icons/RefreshArrowsIcon'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'EcocoachPowerTrolleyMeasurement',
    components: {
        BatteryIcon,
        EnergyIcon,
        FlashIcon,
        LineChartIcon,
        MeasurementItem,
        StopwatchIcon,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        batteryPercentage() {
            return this.getMeasurementItem('battery_percentage')
        },
        batteryStatus() {
            return this.getMeasurementItem('battery_status')
        },
        batteryVoltage() {
            return this.getMeasurementItem('voltage_0')
        },
        energyRemaining() {
            const data = this.getMeasurementItem('energy_0')
            // convert Wh to kWh
            data.value = (data.value / 1000).toFixed(2)
            return data
        },
        powerUpCounter() {
            return this.getMeasurementItem('d1_count')
        },
        runningTime() {
            const item = this.getMeasurementItem('running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        temperature() {
            return this.getMeasurementItem('temperature_0')
        },
        temperatureAfe() {
            return this.getMeasurementItem('temperature_1')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "batteryPercentage": "State of charge",
        "batteryVoltage": "Battery voltage",
        "energyRemaining": "Remaining energy",
        "powerUpCounter": "Power up counter",
        "runningTime": "Operating hours",
        "temperature": "Battery temperature",
        "temperatureAfe": "AFE temperature"
    },
    "de": {
        "batteryPercentage": "Ladezustand",
        "batteryVoltage": "Batteriespannung",
        "energyRemaining": "Restliche Energie",
        "powerUpCounter": "Einschaltzähler",
        "runningTime": "Betriebsstunden",
        "temperature": "Batterietemperatur",
        "temperatureAfe": "AFE Temperatur"
    },
    "fr": {
        "batteryPercentage": "Etat de la charge",
        "batteryVoltage": "Tension de la batterie",
        "energyRemaining": "Energie restante",
        "powerUpCounter": "Compteur de mise en route",
        "runningTime": "Heures de fonctionnement",
        "temperature": "Température de la batterie",
        "temperatureAfe": "Température AFE"
    },
    "it": {
        "batteryPercentage": "Stato di carica",
        "batteryVoltage": "Livello dell batteria",
        "energyRemaining": "Energia residua",
        "powerUpCounter": "Contatore di accensione",
        "runningTime": "Ore di funzionamento",
        "temperature": "Battery Temperatura",
        "temperatureAfe": "AFE Temperatura"
    }
}
</i18n>
