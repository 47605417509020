var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.batteryPercentage)?_c('MeasurementItem',{attrs:{"title":_vm.batteryPercentage.title,"datetime":_vm.batteryPercentage.lastUpdated,"link-to":_vm.batteryPercentage.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.batteryPercentage.value,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('batteryPercentage'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.batteryPercentage.value)+" ")])]},proxy:true}],null,false,677418504)}):(_vm.batteryStatus)?_c('MeasurementItem',{attrs:{"title":_vm.batteryStatus.title,"datetime":_vm.batteryStatus.lastUpdated,"link-to":_vm.batteryVoltage
                ? {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.batteryVoltage.key,
                      },
                      query: {
                          assetType: 'ecocoach',
                      },
                  }
                : null},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-status":_vm.batteryStatus.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.$t( 'shared.measurementOptions.battery_status.' + _vm.batteryStatus.value ))+" "),(_vm.batteryVoltage)?[_vm._v(" ("+_vm._s(_vm.batteryVoltage.value)+" V) ")]:_vm._e()],2)]},proxy:true}])}):(!_vm.batteryPercentage && _vm.batteryVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":_vm.batteryVoltage.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('batteryVoltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}])}):_vm._e(),(_vm.temperature)?_c('MeasurementItem',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.temperature.key,
            },
            query: {
                assetType: 'ecocoach',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,239159870)}):_vm._e(),(_vm.temperatureAfe)?_c('MeasurementItem',{attrs:{"title":_vm.temperatureAfe.title,"datetime":_vm.temperatureAfe.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.temperatureAfe.key,
            },
            query: {
                assetType: 'ecocoach',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperatureAfe'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperatureAfe.value)+" ")])]},proxy:true}],null,false,2674138206)}):_vm._e(),(_vm.runningTime)?_c('MeasurementItem',{attrs:{"title":_vm.runningTime.title,"datetime":_vm.runningTime.lastUpdated},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('StopwatchIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('runningTime'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.runningTime.textDuration)+" ")])]},proxy:true}],null,false,40199177)}):_vm._e(),(_vm.energyRemaining)?_c('MeasurementItem',{attrs:{"title":_vm.energyRemaining.title,"datetime":_vm.energyRemaining.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.energyRemaining.key,
            },
            query: {
                assetType: 'ecocoach',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('EnergyIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('energyRemaining'))+": "),_c('h3',{staticClass:"sensor-data-energy"},[_vm._v(" "+_vm._s(_vm.energyRemaining.value)+" ")])]},proxy:true}],null,false,3064969552)}):_vm._e(),(_vm.powerUpCounter)?_c('MeasurementItem',{attrs:{"title":_vm.powerUpCounter.title,"datetime":_vm.powerUpCounter.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.powerUpCounter.key,
            },
            query: {
                assetType: 'ecocoach',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('LineChartIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('powerUpCounter'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.powerUpCounter.value)+" ")])]},proxy:true}],null,false,1091715570)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }