<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="cls-1" cx="12" cy="17.25" r="3" />

        <line class="cls-1" x1="12" y1="14.25" x2="12" y2="16.5" />

        <path
            class="cls-1"
            d="M22.432,21.3A1.5,1.5,0,0,1,21,23.25H3A1.5,1.5,0,0,1,1.568,21.3l2.813-9A1.5,1.5,0,0,1,5.812,11.25H18.187A1.5,1.5,0,0,1,19.619,12.3Z"
        />

        <path
            class="cls-1"
            d="M3,.75a.75.75,0,0,0-.692,1.039A10.5,10.5,0,0,0,12,8.25a10.5,10.5,0,0,0,9.689-6.462A.75.75,0,0,0,21,.75Z"
        />

        <line class="cls-1" x1="9" y1="7.815" x2="9" y2="11.25" />

        <line class="cls-1" x1="15" y1="7.815" x2="15" y2="11.25" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ScalesIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.cls-1 {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
