<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="12.001" cy="12" r="4.5" />

        <line class="a" x1="12.001" y1="1.5" x2="12.001" y2="4.5" />

        <line class="a" x1="12.001" y1="19.5" x2="12.001" y2="22.5" />

        <line class="a" x1="22.501" y1="12" x2="19.501" y2="12" />

        <line class="a" x1="4.501" y1="12" x2="1.501" y2="12" />

        <line class="a" x1="19.425" y1="4.576" x2="17.304" y2="6.697" />

        <line class="a" x1="6.697" y1="17.304" x2="4.576" y2="19.425" />

        <line class="a" x1="19.425" y1="19.425" x2="17.304" y2="17.304" />

        <line class="a" x1="6.697" y1="6.697" x2="4.576" y2="4.576" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
