<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        fill-color="transparent"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M 22.999346,11.811022 A 11.161339,11.160122 0 0 1 11.999999,22.99814 10.836357,10.835176 0 0 1 1.0006543,12.191959 11.161339,11.160122 0 0 1 11.999999,1.0018414 10.837356,10.836177 0 0 1 22.999346,11.811022 Z"
            style="stroke-width:2"
        />
        <line
            class="a"
            x1="11.91644"
            y1="13.505836"
            x2="11.91644"
            y2="6.1842232"
            style="stroke-width:2;"
        />
        <path
            class="a"
            d="m 11.904305,16.857812 a 0.33027793,0.33024214 0 0 0 -0.324885,0.343721 0.34241059,0.34237347 0 0 0 0.341063,0.330243 v 0 a 0.33162601,0.33159005 0 0 0 0.324885,-0.343721 0.34106251,0.34102555 0 0 0 -0.32893,-0.330243 h -0.0068"
            style="stroke-width:2.2;"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'WarningIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#d32f2f',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: #d32f2f;
    stroke-linecap: round;
    stroke-linejoin: round;
}
</style>
