<template>
    <div>
        <MeasurementItem
            v-if="waterLevel"
            :title="waterLevel.title"
            :datetime="waterLevel.lastUpdated"
            :link-to="waterLevel.link"
        >
            <template #icon>
                <BarChartIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.water_level') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ waterLevel.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="potential"
            :title="potential.title"
            :datetime="potential.lastUpdated"
            :link-to="potential.link"
        >
            <template #icon>
                <LineChartIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.potential') }}:

                <h3>{{ potential.value }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="isAdminOrStaff && tau"
            :title="tau.title"
            :datetime="tau.lastUpdated"
            :link-to="tau.link"
        >
            <template #icon>
                <LineChartIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.tau') }}:

                <h3>{{ tau.value }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="
                temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [humidity.key, temperature.key],
                          },
                      }
                    : humidity.link
            "
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="
                humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [humidity.key, temperature.key],
                          },
                      }
                    : temperature.link
            "
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <BatteryMeasurementsFragment v-bind="$props" />

        <MeasurementItem
            v-if="probeState"
            :title="probeState.title"
            :datetime="probeState.lastUpdated"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="probeState.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.probe_state') }}:

                <h3>
                    {{ $t(probeState.value ? 'statusOk' : 'statusNotOk') }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="isAdminOrStaff && upperLoop"
            :title="upperLoop.title"
            :datetime="upperLoop.lastUpdated"
            :link-to="upperLoop.link"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="upperLoop.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.upper_loop') }}:

                <h3>
                    {{ $t(upperLoop.value ? 'loopPositive' : 'loopNegative') }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="isAdminOrStaff && lowerLoop"
            :title="lowerLoop.title"
            :datetime="lowerLoop.lastUpdated"
            :link-to="lowerLoop.link"
        >
            <template #icon>
                <FilledCircleIcon
                    width="24"
                    height="24"
                    :class="lowerLoop.value ? 'positive' : 'negative'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.lower_loop') }}:

                <h3>
                    {{ $t(lowerLoop.value ? 'loopPositive' : 'loopNegative') }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import BarChartIcon from './icons/BarChartIcon'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DropIcon from './icons/DropIcon'
import FilledCircleIcon from './icons/FilledCircleIcon'
import LineChartIcon from './icons/LineChartIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'FlatRoofLightMeasurement',
    components: {
        BarChartIcon,
        BatteryMeasurementsFragment,
        DropIcon,
        FilledCircleIcon,
        LineChartIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        ...mapGetters('auth', ['isAdminOrStaff']),
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        lowerLoop() {
            return this.getMeasurementItem('lower_loop')
        },
        potential() {
            return this.getMeasurementItem('potential')
        },
        tau() {
            return this.getMeasurementItem('tau')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        probeState() {
            return this.getMeasurementItem('probe_state')
        },
        upperLoop() {
            return this.getMeasurementItem('upper_loop')
        },
        waterLevel() {
            return this.getMeasurementItem('water_level')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "statusOk": "OK",
        "statusNotOk": "No answer",
        "loopNegative": "Open",
        "loopPositive": "OK"
    },
    "de": {
        "statusOk": "OK",
        "statusNotOk": "Keine Antwort",
        "loopNegative": "Offen",
        "loopPositive": "OK"
    },
    "fr": {
        "statusOk": "OK",
        "statusNotOk": "Aucune réponse",
        "loopNegative": "Ouvert",
        "loopPositive": "OK"
    },
    "it": {
        "statusOk": "OK",
        "statusNotOk": "Nessuna risposta",
        "loopNegative": "Aperto",
        "loopPositive": "OK"
    }
}
</i18n>

<style lang="scss" scoped>
.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
