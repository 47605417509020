var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.temperature)?_c('MeasurementItem',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":_vm.humidity
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.temperature.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,3410695502)}):_vm._e(),(_vm.humidity)?_c('MeasurementItem',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":_vm.temperature
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.humidity.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,3770266769)}):_vm._e(),(_vm.windDirection)?_c('MeasurementItem',{attrs:{"title":_vm.windDirection.title,"datetime":_vm.windDirection.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.windDirection.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('WindIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.wind_direction'))+": "),_c('h3',{staticClass:"sensor-data-degrees"},[_vm._v(_vm._s(_vm.windDirection.value))])]},proxy:true}],null,false,1509018924)}):_vm._e(),(_vm.windSpeed)?_c('MeasurementItem',{attrs:{"title":_vm.windSpeed.title,"datetime":_vm.windSpeed.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.windSpeed.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('WindIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.wind_speed'))+": "),_c('h3',{staticClass:"sensor-data-speed-m"},[_vm._v(" "+_vm._s(_vm.windSpeed.value)+" ")])]},proxy:true}],null,false,3087960556)}):_vm._e(),(_vm.maximumWindSpeed)?_c('MeasurementItem',{attrs:{"title":_vm.maximumWindSpeed.title,"datetime":_vm.maximumWindSpeed.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.maximumWindSpeed.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('WindIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.maximum_wind_speed'))+": "),_c('h3',{staticClass:"sensor-data-speed-m"},[_vm._v(" "+_vm._s(_vm.maximumWindSpeed.value)+" ")])]},proxy:true}],null,false,4031050867)}):_vm._e(),(_vm.barometricPressure)?_c('MeasurementItem',{attrs:{"title":_vm.barometricPressure.title,"datetime":_vm.barometricPressure.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.barometricPressure.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SpeedIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.barometric_pressure'))+": "),_c('h3',{staticClass:"sensor-data-barometric-pressure"},[_vm._v(" "+_vm._s(_vm.barometricPressure.value)+" ")])]},proxy:true}],null,false,1516928938)}):_vm._e(),(_vm.vaporPressure)?_c('MeasurementItem',{attrs:{"title":_vm.vaporPressure.title,"datetime":_vm.vaporPressure.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.vaporPressure.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SpeedIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.vapor_pressure'))+": "),_c('h3',{staticClass:"sensor-data-kpa"},[_vm._v(_vm._s(_vm.vaporPressure.value))])]},proxy:true}],null,false,490676354)}):_vm._e(),(_vm.solarRadiation)?_c('MeasurementItem',{attrs:{"title":_vm.solarRadiation.title,"datetime":_vm.solarRadiation.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.solarRadiation.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SunIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.solar_radiation'))+": "),_c('h3',{staticClass:"sensor-data-solar-radiation"},[_vm._v(" "+_vm._s(_vm.solarRadiation.value)+" ")])]},proxy:true}],null,false,768333818)}):_vm._e(),(_vm.precipitationAbsolute)?_c('MeasurementItem',{attrs:{"title":_vm.precipitationAbsolute.title,"datetime":_vm.precipitationAbsolute.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.precipitationAbsolute.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.precipitation_absolute'))+": "),_c('h3',{staticClass:"sensor-data-distance-mm"},[_vm._v(" "+_vm._s(_vm.precipitationAbsolute.value)+" ")])]},proxy:true}],null,false,2043393624)}):_vm._e(),(_vm.lightningStrikeCount)?_c('MeasurementItem',{attrs:{"title":_vm.lightningStrikeCount.title,"datetime":_vm.lightningStrikeCount.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.lightningStrikeCount.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.lightning_strike_count'))+": "),_c('h3',[_vm._v(_vm._s(_vm.lightningStrikeCount.value))])]},proxy:true}],null,false,903543344)}):_vm._e(),(_vm.lightningAverageDistance)?_c('MeasurementItem',{attrs:{"title":_vm.lightningAverageDistance.title,"datetime":_vm.lightningAverageDistance.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.lightningAverageDistance.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.lightning_average_distance'))+": "),_c('h3',{staticClass:"sensor-data-distance-km"},[_vm._v(" "+_vm._s(_vm.lightningAverageDistance.value)+" ")])]},proxy:true}],null,false,112383960)}):_vm._e(),(_vm.batteryVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":_vm.batteryVoltage.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":100}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery_voltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,64675613)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }