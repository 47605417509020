<template>
    <div>
        <div
            class="l-inline l-gap-2 l-padded"
            :class="{
                'u-bb': isMeasurementsAvailable,
            }"
        >
            <AssetAvatar :tracker="tracker" :size="64" linkable />

            <div class="l-stack l-gap-1 l-full-width">
                <h3>{{ tracker.name }}</h3>

                <div v-if="tracker.position.timestamp" class="detail-line">
                    <p>{{ $t('lastPosition') }}:</p>

                    <TimeAgo :from-datetime="tracker.position.timestamp" />

                    <IconButton @click="showDateInfoBox = !showDateInfoBox">
                        <InfoIcon :width="13" :height="13" />
                    </IconButton>
                </div>

                <transition name="dropdown">
                    <InfoBox v-if="showDateInfoBox">
                        {{ $t('lastPositionInfo') }}
                    </InfoBox>
                </transition>

                <div v-if="tracker.last_contact" class="detail-line">
                    <p>{{ $t('lastContact') }}:</p>

                    <TimeAgo :from-datetime="tracker.last_contact" />
                </div>
            </div>
        </div>

        <div v-if="isMeasurementsAvailable">
            <div class="l-padded u-bb">{{ $t('measurements') }}</div>

            <MeasurementsList :is-shared="true" :tracker="tracker" />
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import AssetAvatar from '../AssetAvatar'
import IconButton from '../IconButton'
import InfoBox from '../InfoBox'
import InfoIcon from '../icons/InfoIcon'
import MeasurementsList from '../MeasurementsList'
import TimeAgo from '../TimeAgo'

export default {
    name: 'SharedAssetDetailsView',
    components: {
        AssetAvatar,
        IconButton,
        InfoBox,
        InfoIcon,
        MeasurementsList,
        TimeAgo,
    },
    props: {
        id: {
            type: [Number, String],
            required: true,
        },
    },
    data() {
        return {
            showDateInfoBox: false,
        }
    },
    computed: {
        ...mapState('sharing', ['sharedTrackers']),
        isMeasurementsAvailable() {
            return (
                this.tracker?.sensor_data &&
                Object.keys(this.tracker.sensor_data).length
            )
        },
        tracker() {
            return this.sharedTrackers.find(tracker => tracker.id == this.id)
        },
    },
    watch: {
        tracker: {
            immediate: true,
            handler() {
                if (this.tracker) {
                    this.setActiveSharedTracker(this.tracker)
                } else {
                    this.$router.push(
                        this.$route.matched[0].path.replace(
                            ':token',
                            this.$route.params.token
                        )
                    )
                }
            },
        },
    },
    beforeRouteLeave(to, from, next) {
        this.setActiveSharedTracker(null)
        next()
    },
    methods: {
        ...mapMutations('sharing', ['setActiveSharedTracker']),
    },
}
</script>

<i18n>
{
    "en": {
        "lastContact": "Last contact",
        "lastPosition": "Last position",
        "lastPositionInfo": "Trackers do not transmit their position with every message. The latest point of contact with a tracker could have therefore occurred after the last received geographic location.",
        "measurements": "Measurements"
    },
    "de": {
        "lastContact": "Letzter Kontakt",
        "lastPosition": "Letzte Position",
        "lastPositionInfo": "Der Tracker schickt nicht in jeder Nachricht seinen Standort. Daraus folgt, dass der letzte Kontakt mit dem Tracker später sein kann als die letzte Standortmessung.",
        "measurements": "Messungen"
    },
    "fr": {
        "lastContact": "Dernier contact",
        "lastPosition": "Dernière position",
        "lastPositionInfo": "Les trackers ne transmettent pas leur position avec chaque message. Le dernier point de contact avec un tracker peut donc avoir eu lieu après la dernière position géographique reçue.",
        "measurements": "Mesures"
    },
    "it": {
        "lastContact": "Ultimo Contatto",
        "lastPosition": "Ultimo Posizione",
        "lastPositionInfo": "Il tracker non invia la sua posizione in ogni messaggio. Ciò significa che l'ultimo contatto con l'inseguitore può essere successivo all'ultima misurazione della posizione.",
        "measurements": "Misure"
    }
}
</i18n>

<style lang="scss" scss>
.detail-line {
    font-size: $fs-small;

    p {
        display: inline;
        margin-right: 0.3em;
    }

    button {
        margin-left: 0.3em;
        vertical-align: bottom;
    }
}
</style>
