<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="12" cy="10.319" r="2.25" />

        <line class="a" x1="12" y1="12.569" x2="12" y2="22.253" />

        <path class="a" d="M19.286,18.89a11.249,11.249,0,0,0,0-17.143" />

        <path class="a" d="M16.371,15.462a6.749,6.749,0,0,0,0-10.286" />

        <path class="a" d="M4.714,18.89a11.249,11.249,0,0,1,0-17.143" />

        <path class="a" d="M7.629,15.462a6.749,6.749,0,0,1,0-10.286" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'WifiSignalIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
