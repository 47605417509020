<template>
    <div>
        <MeasurementItem
            v-if="airTemperature"
            :title="airTemperature.title"
            :datetime="airTemperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: airTemperature.key,
                },
                query: {
                    assetType: 'roadMaintenance',
                },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('airTemperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ airTemperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="airHumidity"
            :title="airHumidity.title"
            :datetime="airHumidity.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: airHumidity.key,
                },
                query: {
                    assetType: 'roadMaintenance',
                },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('airHumidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ airHumidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="dewPoint"
            :title="dewPoint.title"
            :datetime="dewPoint.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: dewPoint.key,
                },
                query: {
                    assetType: 'roadMaintenance',
                },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('dewPoint') }}:

                <h3 class="sensor-data-temperature">
                    {{ dewPoint.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="surfaceTemperature"
            :title="surfaceTemperature.title"
            :datetime="surfaceTemperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: surfaceTemperature.key,
                },
                query: {
                    assetType: 'roadMaintenance',
                },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('surfaceTemperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ surfaceTemperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="sensorHeadTemperature"
            :title="sensorHeadTemperature.title"
            :datetime="sensorHeadTemperature.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: sensorHeadTemperature.key,
                },
                query: {
                    assetType: 'roadMaintenance',
                },
            }"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('sensorHeadTemperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ sensorHeadTemperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: batteryVoltage.key,
                },
            }"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery_voltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'RoadMaintenanceMeasurement',
    components: {
        BatteryIcon,
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        airHumidity() {
            return this.getMeasurementItem('humidity')
        },
        airTemperature() {
            return this.getMeasurementItem('temperature_0')
        },
        dewPoint() {
            return this.getMeasurementItem('temperature_3')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        sensorHeadTemperature() {
            return this.getMeasurementItem('temperature_2')
        },
        surfaceTemperature() {
            return this.getMeasurementItem('temperature_1')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "airHumidity": "Air humidity",
        "airTemperature": "Air temperature",
        "dewPoint": "Dew point",
        "sensorHeadTemperature": "Sensor head temperature",
        "surfaceTemperature": "Surface temperature"
    },
    "de": {
        "airHumidity": "Luftfeuchtigkeit",
        "airTemperature": "Lufttemperatur",
        "dewPoint": "Taupunkt",
        "sensorHeadTemperature": "Temperatur des Sensorkopfes",
        "surfaceTemperature": "Oberflächentemperatur"
    },
    "fr": {
        "airHumidity": "Humidité de l'air",
        "airTemperature": "Température de l'air",
        "dewPoint": "Point de rosée",
        "sensorHeadTemperature": "Température de la tête du capteur",
        "surfaceTemperature": "Température de surface"
    },
    "it": {
        "airHumidity": "Umidità dell'aria",
        "airTemperature": "Temperatura dell'aria",
        "dewPoint": "Punto di rugiada",
        "sensorHeadTemperature": "Temperatura della testa del sensore",
        "surfaceTemperature": "Temperatura di superficie"
    }
}
</i18n>
