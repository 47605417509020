<template>
    <div>
        <MeasurementItem
            v-if="fillLevel"
            :title="fillLevel.title"
            :datetime="fillLevel.lastUpdated"
            :link-to="fillLevel.link"
        >
            <template #icon>
                <BinFillIcon
                    width="24"
                    height="24"
                    :fullness="fillLevel.value"
                    :fill-color="fillLevel.value >= 20 ? '#41b883' : '#d32f2f'"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.fill_level') }}:

                <h3 class="sensor-data-percentage">
                    {{ fillLevel.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="mass"
            :title="mass.title"
            :datetime="mass.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: mass.key,
                },
                query: {
                    assetType: 'silo',
                },
            }"
        >
            <template #icon>
                <ScalesIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.mass') }}:

                <h3>{{ mass.value }} t</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="volume"
            :title="volume.title"
            :datetime="volume.lastUpdated"
            :link-to="volume.link"
        >
            <template #icon>
                <CylinderIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.volume') }}:

                <h3>{{ volume.value }} m³</h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BinFillIcon from './icons/BinFillIcon'
import CylinderIcon from './icons/CylinderIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ScalesIcon from './icons/ScalesIcon'

export default {
    name: 'SiloMeasurement',
    components: {
        BinFillIcon,
        CylinderIcon,
        MeasurementItem,
        ScalesIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        fillLevel() {
            const data = this.getMeasurementItem('fill_level')
            if (data) {
                data.value = Math.round(data.value * 100)
            }
            return data
        },
        mass() {
            const data = this.getMeasurementItem('mass')
            if (data) {
                data.value = (data.value / 1000).toFixed(2)
            }
            return data
        },
        volume() {
            return this.getMeasurementItem('volume')
        },
    },
}
</script>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
