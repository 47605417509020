var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.battery)?_c('MeasurementItem',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":_vm.battery.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.battery.value,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,3831347632)}):_vm._e(),(_vm.voltage)?_c('MeasurementItem',{attrs:{"title":_vm.voltage.title,"datetime":_vm.voltage.lastUpdated,"link-to":{
            name: 'chartsCombined',
            params: {
                id: _vm.tracker.id,
                simulated: _vm.isSimulated,
            },
            query: {
                assetType: 'fence-guard',
                measurement: _vm.voltage.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"measurement-value-range"},_vm._l((_vm.voltage.key),function(measurement,i){return _c('div',{key:i,staticClass:"l-inline l-center-v l-spread",class:{ highlighted: _vm.voltage.value[i] < 3 }},[_c('span',{staticClass:"t-small"},[_vm._v(" "+_vm._s(_vm.$t(measurement))+" ")]),_c('strong',{staticClass:"sensor-data-voltage-k"},[_vm._v(" "+_vm._s(_vm.voltage.value[i])+" ")])])}),0)]},proxy:true}],null,false,182966457)}):_vm._e(),(_vm.voltage && _vm.status)?_c('MeasurementItem',{attrs:{"title":_vm.status.title,"datetime":_vm.status.lastUpdated,"link-to":{
            name: 'fenceGuardStatusChart',
            params: { id: _vm.tracker.id, simulated: _vm.isSimulated },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.status.value ? 'green' : 'red',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"measurement-value"},[_vm._v(" "+_vm._s(_vm.status.value ? _vm.$t('ok') : _vm.$t('okNot'))+" ")])]},proxy:true}],null,false,3672310084)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }