<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <ellipse class="a" cx="12" cy="5.25" rx="9.75" ry="4.5" />

        <ellipse class="a" cx="12" cy="18.75" rx="9.75" ry="4.5" />

        <line class="a" x1="21.75" y1="5.25" x2="21.75" y2="18.75" />

        <line class="a" x1="2.25" y1="5.25" x2="2.25" y2="18.75" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'CylinderIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
