<template>
    <span class="l-inline l-gap-0">
        <span
            v-for="item in progressRange"
            :key="item"
            class="progress-dot"
            :class="{
                'progress-dot--checked': item > max - value,
            }"
        />
    </span>
</template>

<script>
export default {
    name: 'ProgressDots',
    props: {
        max: {
            type: [Number, String],
            default: 1,
        },
        value: {
            type: [Number, String],
            default: 0,
        },
    },
    computed: {
        progressRange() {
            return Array.from({ length: this.max }, (_, i) => i + 1)
        },
    },
}
</script>

<style lang="scss" scoped>
.progress-dot {
    margin-top: 6px;
    margin-bottom: 6px;
    width: 8px;
    height: 8px;
    border: 2px solid $color-primary-light;
    border-radius: 50%;
    background-color: $color-primary-light;

    &--checked {
        background-color: transparent;
    }
}
</style>
