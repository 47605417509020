<template>
    <MeasurementItem>
        <template #content>
            <div v-if="isLoading" class="no-padding l-full-width">
                <VSpinner size="medium" line-fg-color="black" :speed="1" />
            </div>

            <div v-else class="no-padding">
                <h3>{{ $t('title') }}</h3>

                <p class="l-inline l-gap-2 l-center-v">
                    <span>
                        {{ $t('currentShift') }}:
                        <b>{{ currentShift }}</b>
                    </span>

                    <ProgressDots
                        v-if="remainingDrivingTimes"
                        :value="remainingDrivingTimes.value"
                        :max="2"
                        :title="
                            $tc(
                                'remainingDrivingTimes',
                                remainingDrivingTimes
                                    ? remainingDrivingTimes.value
                                    : 0
                            )
                        "
                    />
                </p>

                <p>
                    {{ $t('pause') }}:
                    <b>{{ totalBreak }}</b>
                </p>

                <p class="l-inline l-gap-2 l-center-v">
                    <span>
                        {{ $t('timeUntilRest') }}:
                        <b>{{ timeUntilRest }}</b>
                    </span>

                    <ProgressDots
                        v-if="remainingRestPeriods"
                        :value="remainingRestPeriods.value"
                        :max="3"
                        :title="
                            $tc(
                                'remainingRestPeriods',
                                remainingRestPeriods
                                    ? remainingRestPeriods.value
                                    : 0
                            )
                        "
                    />
                </p>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import moment from 'moment'
import VSpinner from 'vue-simple-spinner'

import { formatHelper, httpHelper } from '../utils'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ProgressDots from './ProgressDots'

export default {
    name: 'TruckTimeSummaryMeasurementFragment',
    components: {
        MeasurementItem,
        ProgressDots,
        VSpinner,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            isLoading: true,
            totalBreak: 0,
        }
    },
    computed: {
        currentShift() {
            const max = 9 * 60 * 60
            const offset = 1 * 60 * 60
            return formatHelper.hoursAndMinutesDuration(
                this.sensorData.driver_1_current_daily_driving_time
                    ? this.sensorData.driver_1_current_daily_driving_time.value
                    : this.sensorData.driver_1_remaining_time_current_shift
                    ? max +
                      offset -
                      this.sensorData.driver_1_remaining_time_current_shift
                          .value
                    : 0
            )
        },
        remainingDrivingTimes() {
            return this.getMeasurementItem(
                'driver_1_remaining_10h_driving_times'
            )
        },
        remainingRestPeriods() {
            return this.getMeasurementItem(
                'driver_1_remaining_reduced_daily_rest_periods'
            )
        },
        timeUntilRest() {
            const max = 15 * 60 * 60
            const item = this.sensorData
                .driver_1_time_left_until_new_daily_rest_period
            return formatHelper.hoursAndMinutesDuration(
                item ? max - item.value : 0
            )
        },
    },
    watch: {
        'sensorData.driver_id': {
            immediate: true,
            async handler(newDriverId, oldDriverId) {
                if (newDriverId?.value === oldDriverId?.value) {
                    return
                }
                try {
                    this.isLoading = true
                    const { data } = await httpHelper.getRecursively(
                        '/tachograph/driver-activities/',
                        {
                            params: {
                                driver_id: newDriverId?.value,
                                start: moment()
                                    .startOf('day')
                                    .toDate(),
                                end: moment()
                                    .endOf('day')
                                    .toDate(),
                            },
                        }
                    )
                    this.totalBreak = formatHelper.hoursAndMinutesDuration(
                        data.results.reduce((acc, cur) => {
                            return cur.activity === 'Rest'
                                ? acc +
                                      (new Date(cur.end) - new Date(cur.start))
                                : acc
                        }, 0) / 1000
                    )
                } finally {
                    this.isLoading = false
                }
            },
        },
    },
    methods: {
        formatTime(time) {
            return moment(time).format('HH:mm')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "currentShift": "Driving time",
        "pause": "Break",
        "remainingDrivingTimes": "{n} remaining 10-hour driving time | {n} remaining 10-hour driving times",
        "remainingRestPeriods": "{n} remaining reduced daily rest period | {n} remaining reduced daily rest periods",
        "timeUntilRest": "Working time",
        "title": "Time summary"
    },
    "de": {
        "currentShift": "Lenkzeit",
        "pause": "Pause",
        "remainingDrivingTimes": "{n} verbleibende 10-Stunden Fahrzeit | {n} verbleibende 10-Stunden Fahrzeiten",
        "remainingRestPeriods": "{n} verbleibende reduzierte tägliche Ruhezeit | {n} verbleibende reduzierte tägliche Ruhezeiten",
        "timeUntilRest": "Arbeitszeit",
        "title": "Zusammenfassung der Zeiten"
    },
    "fr": {
        "currentShift": "Temps de conduite",
        "pause": "Pause",
        "remainingDrivingTimes": "{n} période de conduite de 10 heures restante | {n} périodes de conduite de 10 heures restantes",
        "remainingRestPeriods": "{n} période de repos quotidien réduite restante | {n} périodes de repos quotidien réduites restantes",
        "timeUntilRest": "Temps de travail",
        "title": "Résumé des temps"
    },
    "it": {
        "currentShift": "Tempo di guida",
        "pause": "Rottura",
        "remainingDrivingTimes": "{n} tempo di guida di 10 ore rimanente | {n} tempi di guida di 10 ore rimanenti",
        "remainingRestPeriods": "{n} periodo di riposo giornaliero ridotto rimanente | {n} periodi di riposo giornaliero ridotti rimanenti",
        "timeUntilRest": "Orario di lavoro",
        "title": "Riassunto del tempo"
    }
}
</i18n>
