<template>
    <div
        class="l-padded l-full-width l-inline l-spread l-center-v list-item"
        :class="{ clickable }"
        v-on="$listeners"
    >
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ListItem',
    props: {
        clickable: {
            type: Boolean,
            default: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.list-item {
    font-size: $fs-base;

    &.clickable {
        &:hover {
            background-color: $color-gray-lighter;
        }
    }
}
</style>
