<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="18.375" y1="17.625" x2="18.375" y2="17.625" />

        <path
            class="a"
            d="M18.375,17.625A.375.375,0,1,0,18.75,18a.375.375,0,0,0-.375-.375"
        />

        <line class="a" x1="15" y1="17.625" x2="15" y2="17.625" />

        <path
            class="a"
            d="M15,17.625a.375.375,0,1,0,.375.375A.375.375,0,0,0,15,17.625"
        />

        <path
            class="a"
            d="M23.25,18a4.5,4.5,0,0,1-4.5,4.5H5.25a4.5,4.5,0,0,1,0-9h13.5A4.5,4.5,0,0,1,23.25,18Z"
        />

        <path
            class="a"
            d="M23.131,16.973,20.043,3.815A3,3,0,0,0,17.123,1.5H6.878A3,3,0,0,0,3.957,3.815L.869,16.973"
        />

        <line class="a" x1="5.25" y1="18" x2="9" y2="18" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TachographIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
