<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M12,4.5A11.336,11.336,0,0,0,.75,15.923V18a1.5,1.5,0,0,0,1.5,1.5h19.5a1.5,1.5,0,0,0,1.5-1.5v-2.08A11.336,11.336,0,0,0,12,4.5Z"
        />

        <path class="a" d="M9,19.5v-.75a3,3,0,0,1,6,0v.75" />

        <line class="a" x1="14.25" y1="9.003" x2="12" y2="15.753" />

        <line class="a" x1="4.875" y1="15.003" x2="4.875" y2="15.003" />

        <path
            class="a"
            d="M4.875,15a.375.375,0,1,0,.375.375A.375.375,0,0,0,4.875,15"
        />

        <line class="a" x1="6.375" y1="11.253" x2="6.375" y2="11.253" />

        <path
            class="a"
            d="M6.375,11.253a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375"
        />

        <line class="a" x1="19.125" y1="15.003" x2="19.125" y2="15.003" />

        <path
            class="a"
            d="M19.125,15a.375.375,0,1,0,.375.375A.375.375,0,0,0,19.125,15"
        />

        <line class="a" x1="17.625" y1="11.253" x2="17.625" y2="11.253" />

        <path
            class="a"
            d="M17.625,11.253a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375"
        />

        <line class="a" x1="9.375" y1="8.253" x2="9.375" y2="8.253" />

        <path
            class="a"
            d="M9.375,8.253a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'SpeedIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
