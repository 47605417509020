var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MeasurementItem',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"no-padding l-full-width l-inline l-gap-2 remaining-stats"},[_c('div',{staticClass:"l-stack l-gap-1"},[_c('span',{attrs:{"title":_vm.$t('currentShift')}},[_c('SteeringWheelIcon',{attrs:{"width":"20","height":"20"}})],1),_c('span',{attrs:{"title":_vm.$t('breakTime')}},[_c('TeaCupIcon',{attrs:{"width":"20","height":"20"}})],1),_c('span',{attrs:{"title":_vm.$t('timeUntilRest')}},[_c('HistoryClockIcon',{attrs:{"width":"20","height":"20"}})],1)]),_c('div',{staticClass:"l-stack l-gap-1"},[_c('span',{attrs:{"title":_vm.$t('currentShift0')}},[_vm._v(" "+_vm._s(_vm.currentShiftBounds[0])+" ")]),_c('span',{attrs:{"title":_vm.$t('breakTime0')}},[_vm._v(" "+_vm._s(_vm.breakTimeBounds[0])+" ")]),_c('span',{attrs:{"title":_vm.$t('timeUntilRest0')}},[_vm._v(" "+_vm._s(_vm.timeUntilRestBounds[0])+" ")])]),_c('div',{staticClass:"l-grow-1 l-stack l-gap-1"},[_c('div',{staticClass:"l-inline l-center-v"},[_c('div',{staticClass:"line-progress",attrs:{"title":_vm.$t('currentShift')}},[_c('div',{staticClass:"line-progress__value",class:{
                                'line-progress__value--full':
                                    _vm.currentShiftPercentage >= 100,
                            },style:({
                                width: (_vm.currentShiftPercentage + "%"),
                            })})])]),_c('div',{staticClass:"l-inline l-center-v"},[_c('div',{staticClass:"line-progress",attrs:{"title":_vm.$t('breakTime')}},[_c('div',{staticClass:"line-progress__value",class:{
                                'line-progress__value--full':
                                    _vm.breakTimePercentage >= 100,
                            },style:({
                                width: (_vm.breakTimePercentage + "%"),
                            })})])]),_c('div',{staticClass:"l-inline l-center-v"},[_c('div',{staticClass:"line-progress",attrs:{"title":_vm.$t('timeUntilRest')}},[_c('div',{staticClass:"line-progress__value",class:{
                                'line-progress__value--full':
                                    _vm.timeUntilRestPercentage >= 100,
                            },style:({
                                width: (_vm.timeUntilRestPercentage + "%"),
                            })})])])]),_c('div',{staticClass:"l-stack l-gap-1"},[_c('span',{attrs:{"title":_vm.$t('currentShift1')}},[_vm._v(" "+_vm._s(_vm.currentShiftBounds[1])+" ")]),_c('span',{attrs:{"title":_vm.$t('breakTime1')}},[_vm._v(" "+_vm._s(_vm.breakTimeBounds[1])+" ")]),_c('span',{attrs:{"title":_vm.$t('timeUntilRest1')}},[_vm._v(" "+_vm._s(_vm.timeUntilRestBounds[1])+" ")])]),_c('div',{staticClass:"l-stack l-gap-1"},[(_vm.remainingDrivingTimes)?_c('ProgressDots',{attrs:{"value":_vm.remainingDrivingTimes.value,"max":2,"title":_vm.$tc(
                            'remainingDrivingTimes',
                            _vm.remainingDrivingTimes
                                ? _vm.remainingDrivingTimes.value
                                : 0
                        )}}):_vm._e(),_c('div'),(_vm.remainingRestPeriods)?_c('ProgressDots',{attrs:{"value":_vm.remainingRestPeriods.value,"max":3,"title":_vm.$tc(
                            'remainingRestPeriods',
                            _vm.remainingRestPeriods
                                ? _vm.remainingRestPeriods.value
                                : 0
                        )}}):_vm._e()],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }