var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.waterLevel)?_c('MeasurementItem',{attrs:{"title":_vm.waterLevel.title,"datetime":_vm.waterLevel.lastUpdated,"link-to":_vm.waterLevel.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BarChartIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.water_level'))+": "),_c('h3',{staticClass:"sensor-data-distance-mm"},[_vm._v(" "+_vm._s(_vm.waterLevel.value)+" ")])]},proxy:true}],null,false,2764636556)}):_vm._e(),(_vm.humidity)?_c('MeasurementItem',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":_vm.temperature
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : _vm.humidity.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,3770266769)}):_vm._e(),(_vm.temperature)?_c('MeasurementItem',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":_vm.humidity
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.humidity.key, _vm.temperature.key],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.temperature.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,3410695502)}):_vm._e(),_c('BatteryMeasurementsFragment',_vm._b({},'BatteryMeasurementsFragment',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }