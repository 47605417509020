<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <defs>
            <linearGradient
                id="fillLevelGradient"
                x1="0"
                y1="100%"
                x2="0"
                y2="0"
            >
                <stop :offset="fullnessOffset" :stop-color="fillColor" />

                <stop :offset="fullnessOffset" :stop-opacity="0" />
            </linearGradient>
        </defs>

        <polygon
            points="5.6370000000 24.0140000000, 3.4200000000 22.1490000000, 0.0330000000 2.6490000000, 0.0000000000 2.2640000000, 2.2500000000 0.0140000000, 21.7500000000 0.0140000000, 23.4750000000 0.8180000000, 23.9670000000 2.6500000000, 20.5800000000 22.1500000000, 18.3640000000 24.0150000000, 5.6370000000 24.0150000000"
            fill="url(#fillLevelGradient)"
            stroke="none"
        />

        <path
            d="M5.637,24.014c-1.097,0-2.029-0.784-2.217-1.865l-3.387-19.5C0.011,2.522,0,2.392,0,2.264c0-1.241,1.01-2.25,2.25-2.25
                h19.5c0.668,0,1.296,0.293,1.725,0.804c0.427,0.51,0.606,1.177,0.492,1.832l-3.387,19.5c-0.188,1.08-1.12,1.865-2.216,1.865H5.637z
                M2.25,1.514c-0.414,0-0.75,0.336-0.75,0.75c0,0.043,0.004,0.086,0.011,0.128l3.387,19.5c0.062,0.36,0.373,0.621,0.739,0.621
                h12.727c0.366,0,0.677-0.261,0.739-0.621l3.387-19.5c0.038-0.218-0.022-0.441-0.164-0.611c-0.143-0.17-0.352-0.268-0.574-0.268
                H2.25z"
            stroke="none"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'BinFillIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
        fullness: {
            type: [Number, String],
            default: 0,
        },
        fillColor: {
            type: String,
            default: '#000',
        },
    },
    computed: {
        fullnessOffset() {
            return this.fullness + '%'
        },
    },
}
</script>
