<template>
    <div>
        <router-link
            :to="{
                name: 'animalactivitychart',
                params: {
                    id: tracker.id,
                },
            }"
        >
            <ListItem class="l-center">
                <div class="l-inline l-gap-2 l-center">
                    <ActivityIcon width="24" height="24" />

                    <div class="l-stack">
                        <div class="l-inline l-center-v">
                            {{ $t('showActivity') }}
                        </div>
                    </div>
                </div>
                <p>
                    <BarChartIcon :width="20" :height="20" color="lightgray" />
                </p>
            </ListItem>
        </router-link>

        <BatteryMeasurementsFragment v-bind="$props" />
    </div>
</template>

<script>
import ActivityIcon from '../../icons/ActivityIcon'
import BarChartIcon from '../../icons/BarChartIcon'
import BatteryMeasurementsFragment from '../../BatteryMeasurementsFragment'
import ListItem from '../../ListItem'
import MeasurementListHelper from '../../../mixins/MeasurementListHelper'

export default {
    name: 'AnimalMeasurement',
    components: {
        ActivityIcon,
        BarChartIcon,
        BatteryMeasurementsFragment,
        ListItem,
    },
    mixins: [MeasurementListHelper],
}
</script>

<i18n>
{
    "en": {
        "showActivity": "Show activity"
    },
    "de": {
        "showActivity": "Aktivität anzeigen"
    },
    "fr": {
        "showActivity": "Afficher l'activité"
    },
    "it": {
        "showActivity": "Mostra attività"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
