var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.airTemperature)?_c('MeasurementItem',{attrs:{"title":_vm.airTemperature.title,"datetime":_vm.airTemperature.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.airTemperature.key,
            },
            query: {
                assetType: 'roadMaintenance',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('airTemperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.airTemperature.value)+" ")])]},proxy:true}],null,false,2306670622)}):_vm._e(),(_vm.airHumidity)?_c('MeasurementItem',{attrs:{"title":_vm.airHumidity.title,"datetime":_vm.airHumidity.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.airHumidity.key,
            },
            query: {
                assetType: 'roadMaintenance',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('airHumidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.airHumidity.value)+" ")])]},proxy:true}],null,false,1379904225)}):_vm._e(),(_vm.dewPoint)?_c('MeasurementItem',{attrs:{"title":_vm.dewPoint.title,"datetime":_vm.dewPoint.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.dewPoint.key,
            },
            query: {
                assetType: 'roadMaintenance',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('dewPoint'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.dewPoint.value)+" ")])]},proxy:true}],null,false,1113041077)}):_vm._e(),(_vm.surfaceTemperature)?_c('MeasurementItem',{attrs:{"title":_vm.surfaceTemperature.title,"datetime":_vm.surfaceTemperature.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.surfaceTemperature.key,
            },
            query: {
                assetType: 'roadMaintenance',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('surfaceTemperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.surfaceTemperature.value)+" ")])]},proxy:true}],null,false,1674949502)}):_vm._e(),(_vm.sensorHeadTemperature)?_c('MeasurementItem',{attrs:{"title":_vm.sensorHeadTemperature.title,"datetime":_vm.sensorHeadTemperature.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.sensorHeadTemperature.key,
            },
            query: {
                assetType: 'roadMaintenance',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('sensorHeadTemperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.sensorHeadTemperature.value)+" ")])]},proxy:true}],null,false,3063371390)}):_vm._e(),(_vm.batteryVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.batteryVoltage.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":100}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery_voltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,64675613)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }