<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M3,9.75H15a1.5,1.5,0,0,1,1.5,1.5v4.5A7.5,7.5,0,0,1,9,23.25H9a7.5,7.5,0,0,1-7.5-7.5v-4.5A1.5,1.5,0,0,1,3,9.75Z"
        />

        <path
            class="a"
            d="M16.5,11.25H18a4.513,4.513,0,0,1,4.5,4.5h0a4.513,4.513,0,0,1-4.5,4.5H15"
        />

        <line class="a" x1="8.25" y1="14.25" x2="8.25" y2="9.75" />

        <path class="a" d="M14.25.75c1.437,2.873-2.9,3.881-.75,6.75" />

        <path class="a" d="M9.75.75C11.187,3.623,6.849,4.631,9,7.5" />

        <path class="a" d="M5.25.75c1.437,2.873-2.9,3.881-.75,6.75" />

        <rect
            class="a"
            x="6"
            y="14.25"
            width="4.5"
            height="4.5"
            rx="0.5"
            ry="0.5"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TeaCupIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
