<template>
    <MeasurementItem v-if="!isShared">
        <template #content>
            <div class="no-padding l-full-width l-inline l-spread l-gap-2">
                <p>
                    <router-link
                        :to="{
                            name: 'tachograph',
                            params: { id: tracker.id },
                        }"
                        class="l-inline l-center-v l-gap-1 t-underline"
                    >
                        <div>
                            <TachographIcon class="block" />
                        </div>
                        <span>{{ $t('tachographLink') }}</span>
                    </router-link>
                </p>

                <p>
                    <router-link
                        :to="{
                            name: 'triphistory',
                            params: { id: tracker.id },
                        }"
                        class="l-inline l-center-v l-gap-1 t-underline"
                    >
                        <div>
                            <TripMultipleDestinationsIcon class="block" />
                        </div>
                        <span>{{ $t('timelineLink') }}</span>
                    </router-link>
                </p>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import TachographIcon from './icons/TachographIcon'
import TripMultipleDestinationsIcon from './icons/HistoryClockIcon'

export default {
    name: 'TruckLinksMeasurementFragment',
    components: {
        MeasurementItem,
        TachographIcon,
        TripMultipleDestinationsIcon,
    },
    mixins: [MeasurementListHelper],
}
</script>

<i18n>
{
    "en": {
        "tachographLink": "Tachograph data",
        "timelineLink": "Trip history timeline"
    },
    "de": {
        "tachographLink": "Tachograph-Daten",
        "timelineLink": "Zeitleiste Reiseverlauf"
    },
    "fr": {
        "tachographLink": "Données du tachygraphe",
        "timelineLink": "Chronologie du parcours"
    },
    "it": {
        "tachographLink": "Dati del tachigrafo",
        "timelineLink": "Cronologia del viaggio"
    }
}
</i18n>
