<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="7.516" cy="16.487" r="6.75" />

        <line class="a" x1="1.15" y1="18.737" x2="13.881" y2="18.737" />

        <line class="a" x1="1.15" y1="14.237" x2="13.881" y2="14.237" />

        <path class="a" d="M6.059,9.894a21.886,21.886,0,0,0,.51,13.277" />

        <path
            class="a"
            d="M8.972,9.894a21.781,21.781,0,0,1,.794,5.843,21.724,21.724,0,0,1-1.3,7.434"
        />

        <path class="a" d="M12.171,5.987A5.959,5.959,0,0,1,18,11.816" />

        <path class="a" d="M13.92.75a9.749,9.749,0,0,1,9.33,9.288" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'NetworkSignalIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
