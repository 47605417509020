<template>
    <MeasurementItem
        v-if="massSalt"
        :datetime="massSalt.lastUpdated"
        :link-to="
            massSalt && massSand && massBrine
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: tracker.id,
                      },
                      query: {
                          assetType: 'salt',
                          measurement: [
                              massSalt.key,
                              massSand.key,
                              massBrine.key,
                          ],
                      },
                  }
                : massSalt && massSand
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: tracker.id,
                      },
                      query: {
                          assetType: 'salt',
                          measurement: [massSalt.key, massSand.key],
                      },
                  }
                : {
                      name: 'chartsCombined',
                      params: {
                          id: tracker.id,
                      },
                      query: {
                          assetType: 'salt',
                          measurement: massSalt.key,
                      },
                  }
        "
    >
        <template #icon>
            <FilledCircleIcon
                width="24"
                height="24"
                :class="spreadingOn.value ? 'active' : 'inactive'"
            />
        </template>

        <template #content>
            <div class="l-full-width no-padding">
                <h3 class="padding-bottom no-padding">
                    {{ $t(spreadingOn.value ? 'spreading' : 'notSpreading') }}
                </h3>

                <div
                    v-if="massSalt"
                    class="space-between"
                    :title="massSalt.title"
                >
                    <span>
                        {{ $t(`shared.measurements.${massSalt.key}`) }}:
                    </span>

                    <h3 class="sensor-data-mass">
                        {{ massSalt.value }}
                    </h3>
                </div>

                <div
                    v-if="massSand"
                    class="space-between"
                    :title="massSand.title"
                >
                    <span>
                        {{ $t(`shared.measurements.${massSand.key}`) }}:
                    </span>

                    <h3 class="sensor-data-mass">
                        {{ massSand.value }}
                    </h3>
                </div>

                <div
                    v-if="massBrine"
                    class="space-between"
                    :title="massBrine.title"
                >
                    <span>
                        {{ $t(`shared.measurements.${massBrine.key}`) }}:
                    </span>

                    <h3 class="sensor-data-mass">
                        {{ massBrine.value }}
                    </h3>
                </div>

                <div
                    v-if="spreadDensitySalt"
                    class="space-between"
                    :title="spreadDensitySalt.title"
                >
                    <span>
                        {{
                            $t(`shared.measurements.${spreadDensitySalt.key}`)
                        }}:
                    </span>

                    <h3 class="sensor-data-spread-density">
                        {{ spreadDensitySalt.value }}
                    </h3>
                </div>

                <div
                    v-if="spreadDensitySand"
                    class="space-between"
                    :title="spreadDensitySand.title"
                >
                    <span>
                        {{
                            $t(`shared.measurements.${spreadDensitySand.key}`)
                        }}:
                    </span>

                    <h3 class="sensor-data-spread-density">
                        {{ spreadDensitySand.value }}
                    </h3>
                </div>

                <div
                    v-if="spreadDensityBrine"
                    class="space-between"
                    :title="spreadDensityBrine.title"
                >
                    <span>
                        {{
                            $t(`shared.measurements.${spreadDensityBrine.key}`)
                        }}:
                    </span>

                    <h3 class="sensor-data-spread-density">
                        {{ spreadDensityBrine.value }}
                    </h3>
                </div>

                <div
                    v-if="spreadWidth"
                    class="space-between"
                    :title="spreadWidth.title"
                >
                    <span>
                        {{ $t(`shared.measurements.${spreadWidth.key}`) }}:
                    </span>

                    <h3 class="sensor-data-distance">
                        {{ spreadWidth.value }}
                    </h3>
                </div>

                <div
                    v-if="spreadDistance"
                    class="space-between"
                    :title="spreadDistance.title"
                >
                    <span>
                        {{ $t(`shared.measurements.${spreadDistance.key}`) }}:
                    </span>

                    <h3 class="sensor-data-distance-km">
                        {{ spreadDistance.value }}
                    </h3>
                </div>

                <div
                    v-if="spreadRunningTime"
                    class="space-between l-center-v l-gap-0"
                    :title="spreadRunningTime.title"
                >
                    <span>
                        {{
                            $t(`shared.measurements.${spreadRunningTime.key}`)
                        }}:
                    </span>

                    <h3>
                        {{ spreadRunningTime.textDuration }}
                    </h3>
                </div>
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import { formatHelper, measurementHelper } from '../utils'
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'SaltSpreadingMeasurementFragment',
    components: {
        FilledCircleIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        massBrine() {
            return this.getMeasurementItem('mass_brine')
        },
        massSalt() {
            return this.getMeasurementItem('mass_salt')
        },
        massSand() {
            return this.getMeasurementItem('mass_sand')
        },
        spreadDensityBrine() {
            return this.getMeasurementItem('spread_density_brine')
        },
        spreadDensitySalt() {
            return this.getMeasurementItem('spread_density_salt')
        },
        spreadDensitySand() {
            return this.getMeasurementItem('spread_density_sand')
        },
        spreadDistance() {
            const item = this.getMeasurementItem('spread_distance')
            if (item) {
                item.value = measurementHelper.converters.odometer(item.value)
            }
            return item
        },
        spreadRunningTime() {
            const item = this.getMeasurementItem('spread_running_time')
            if (item) {
                item.textDuration = formatHelper.hoursAndMinutesDuration(
                    item.value
                )
            }
            return item
        },
        spreadWidth() {
            return this.getMeasurementItem('spread_width')
        },
        spreadingOn() {
            return this.getMeasurementItem('spreading_on')
        },
    },
}
</script>

<i18n>
    {
        "en": {
            "notSpreading": "Salt spreading inactive",
            "spreading": "Salt spreading active"
        },
        "de": {
            "notSpreading": "Salzstreuung inaktiv",
            "spreading": "Salzstreuung aktiv"
        },
        "fr": {
            "notSpreading": "Epandage de sel inactif",
            "spreading": "Epandage de sel actif"
        },
        "it": {
            "notSpreading": "Spargimento di sale inattivo",
            "spreading": "Spargimento di sale attivo"
        }
    }
    </i18n>

<style lang="scss" scoped>
.space-between {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 25px;
}

.padding-bottom {
    padding-bottom: 0.35em;
}

.active {
    color: $color-green;
}

.inactive {
    color: $color-red;
}
</style>
