<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <circle class="a" cx="6.75" cy="19.013" r="3" />

        <path
            class="a"
            d="M3.75,19.013H2.25a1.5,1.5,0,0,1-1.5-1.5V4.013a1.5,1.5,0,0,1,1.5-1.5h10.5a6,6,0,0,1,6,6v10.5h-9"
        />

        <line class="a" x1="23.25" y1="19.013" x2="18.75" y2="19.013" />

        <rect
            class="a"
            x="3.75"
            y="5.513"
            width="9"
            height="4.5"
            rx="1"
            ry="1"
        />

        <line class="a" x1="8.25" y1="5.513" x2="8.25" y2="10.013" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'TrailerIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
