var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.battery)?_c('MeasurementItem',{attrs:{"title":_vm.battery.title,"datetime":_vm.battery.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.battery.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"battery-level-in-percent":_vm.battery.value,"warn":false,"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.battery.value)+" ")])]},proxy:true}],null,false,3290390336)}):_vm._e(),(_vm.analogInput)?_c('MeasurementItem',{attrs:{"title":_vm.analogInput.title,"datetime":_vm.analogInput.lastUpdated,"link-to":{
            name: 'chartsCombined',
            params: {
                id: _vm.tracker.id,
            },
            query: {
                assetType: 'cluey-leakage',
                measurement: _vm.analogInput.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"measurement-value-range"},_vm._l((_vm.analogInput.key),function(measurement,i){return _c('div',{key:i,staticClass:"l-inline l-center-v l-spread"},[_c('span',{staticClass:"t-small"},[_vm._v(" "+_vm._s(_vm.$t('sensor'))+" "+_vm._s(i + 1)+" ")]),_c('strong',{staticClass:"sensor-data-moser"},[_vm._v(" "+_vm._s(_vm.analogInput.value[i])+" ")])])}),0)]},proxy:true}],null,false,3745531659)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }