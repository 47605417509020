<template>
    <div>
        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="
                humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: temperature.key,
                          },
                      }
            "
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="
                temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: humidity.key,
                          },
                      }
            "
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="windDirection"
            :title="windDirection.title"
            :datetime="windDirection.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: windDirection.key,
                },
            }"
        >
            <template #icon>
                <WindIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.wind_direction') }}:

                <h3 class="sensor-data-degrees">{{ windDirection.value }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="windSpeed"
            :title="windSpeed.title"
            :datetime="windSpeed.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: windSpeed.key,
                },
            }"
        >
            <template #icon>
                <WindIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.wind_speed') }}:

                <h3 class="sensor-data-speed-m">
                    {{ windSpeed.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="maximumWindSpeed"
            :title="maximumWindSpeed.title"
            :datetime="maximumWindSpeed.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: maximumWindSpeed.key,
                },
            }"
        >
            <template #icon>
                <WindIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.maximum_wind_speed') }}:

                <h3 class="sensor-data-speed-m">
                    {{ maximumWindSpeed.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="barometricPressure"
            :title="barometricPressure.title"
            :datetime="barometricPressure.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: barometricPressure.key,
                },
            }"
        >
            <template #icon>
                <SpeedIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.barometric_pressure') }}:

                <h3 class="sensor-data-barometric-pressure">
                    {{ barometricPressure.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="vaporPressure"
            :title="vaporPressure.title"
            :datetime="vaporPressure.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: vaporPressure.key,
                },
            }"
        >
            <template #icon>
                <SpeedIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.vapor_pressure') }}:

                <h3 class="sensor-data-kpa">{{ vaporPressure.value }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="solarRadiation"
            :title="solarRadiation.title"
            :datetime="solarRadiation.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: solarRadiation.key,
                },
            }"
        >
            <template #icon>
                <SunIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.solar_radiation') }}:

                <h3 class="sensor-data-solar-radiation">
                    {{ solarRadiation.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="precipitationAbsolute"
            :title="precipitationAbsolute.title"
            :datetime="precipitationAbsolute.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: precipitationAbsolute.key,
                },
            }"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.precipitation_absolute') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ precipitationAbsolute.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="lightningStrikeCount"
            :title="lightningStrikeCount.title"
            :datetime="lightningStrikeCount.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: lightningStrikeCount.key,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.lightning_strike_count') }}:

                <h3>{{ lightningStrikeCount.value }}</h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="lightningAverageDistance"
            :title="lightningAverageDistance.title"
            :datetime="lightningAverageDistance.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: lightningAverageDistance.key,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.lightning_average_distance') }}:

                <h3 class="sensor-data-distance-km">
                    {{ lightningAverageDistance.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="batteryVoltage"
            :title="batteryVoltage.title"
            :datetime="batteryVoltage.lastUpdated"
            :link-to="batteryVoltage.link"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="100"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery_voltage') }}:

                <h3 class="sensor-data-voltage">
                    {{ batteryVoltage.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import DropIcon from './icons/DropIcon'
import FlashIcon from './icons/FlashIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import SpeedIcon from './icons/SpeedIcon'
import SunIcon from './icons/SunIcon'
import ThermometerIcon from './icons/ThermometerIcon'
import WindIcon from './icons/WindIcon'

export default {
    name: 'WeatherStationMeasurement',
    components: {
        BatteryIcon,
        DropIcon,
        FlashIcon,
        MeasurementItem,
        SpeedIcon,
        SunIcon,
        ThermometerIcon,
        WindIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        barometricPressure() {
            return this.getMeasurementItem('barometric_pressure')
        },
        batteryVoltage() {
            return this.getMeasurementItem('battery_voltage')
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        lightningStrikeCount() {
            return this.getMeasurementItem('lightning_strike_count')
        },
        lightningAverageDistance() {
            const data = this.getMeasurementItem('lightning_average_distance')
            if (data) {
                data.value = (data.value / 1000).toFixed(2)
            }
            return data
        },
        maximumWindSpeed() {
            return this.getMeasurementItem('maximum_wind_speed')
        },
        precipitationAbsolute() {
            return this.getMeasurementItem('precipitation_absolute')
        },
        solarRadiation() {
            return this.getMeasurementItem('solar_radiation')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        vaporPressure() {
            const data = this.getMeasurementItem('vapor_pressure')
            if (data) {
                data.value = (data.value / 1000).toFixed(2)
            }
            return data
        },
        windDirection() {
            return this.getMeasurementItem('wind_direction')
        },
        windSpeed() {
            return this.getMeasurementItem('wind_speed')
        },
    },
}
</script>
