<template>
    <div>
        <MeasurementItem
            v-if="waterLevel"
            :title="waterLevel.title"
            :datetime="waterLevel.lastUpdated"
            :link-to="waterLevel.link"
        >
            <template #icon>
                <BarChartIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.water_level') }}:

                <h3 class="sensor-data-distance-mm">
                    {{ waterLevel.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="
                temperature
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [humidity.key, temperature.key],
                          },
                      }
                    : humidity.link
            "
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="
                humidity
                    ? {
                          name: 'chartsCombined',
                          params: {
                              id: tracker.id,
                          },
                          query: {
                              measurement: [humidity.key, temperature.key],
                          },
                      }
                    : {
                          name: 'charts',
                          params: {
                              id: tracker.id,
                              dataType: temperature.key,
                          },
                      }
            "
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <BatteryMeasurementsFragment v-bind="$props" />
    </div>
</template>

<script>
import BarChartIcon from './icons/BarChartIcon'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'FlatRoofMeasurement',
    components: {
        BarChartIcon,
        BatteryMeasurementsFragment,
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
        waterLevel() {
            return this.getMeasurementItem('water_level')
        },
    },
}
</script>
