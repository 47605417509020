<template>
    <Icon :name="name" :width="width" :height="height" @click="$emit('click')">
        <rect
            class="a"
            x="2.25"
            y="14.25"
            width="19.5"
            height="6"
            rx="1.5"
            ry="1.5"
        />

        <path
            class="a"
            d="M17.25,9.75H6.75a1.5,1.5,0,0,0-1.5,1.5v3h13.5v-3A1.5,1.5,0,0,0,17.25,9.75Z"
        />

        <path
            class="a"
            d="M12.75,9.75C12.3,8.79,9.626,3.055,8.772,1.768a2.214,2.214,0,0,0-3.12-.629,2.213,2.213,0,0,0-.629,3.119c.633.953,3.128,3.775,4.669,5.492Z"
        />

        <line class="a" x1="5.25" y1="20.25" x2="5.25" y2="23.25" />

        <line class="a" x1="12" y1="20.25" x2="12" y2="23.25" />

        <line class="a" x1="18.75" y1="20.25" x2="18.75" y2="23.25" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'ElectronicsSwitchIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
