export const calculateHumidity = (value, assetType) => {
    switch (assetType) {
        case 'cm2-rope':
            return (value > 3400 ? 100 : value / 34).toFixed(2)
        default:
            if (value > 3850) {
                value = 90
            } else if (value > 3644) {
                value = (value - 3645) / 6.8333 + 60
            } else {
                value = value / 81 + 15
            }
            value = value.toFixed(2)
            return value
    }
}
