<template>
    <div>
        <MeasurementItem
            v-if="power"
            :title="power.title"
            :datetime="power.lastUpdated"
            :link-to="power.link"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('power') }}:

                <h3 class="sensor-data-w">
                    {{ power.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="power0"
            :title="power0.title"
            :datetime="power0.lastUpdated"
            :link-to="{
                name: 'chartsCombined',
                params: {
                    id: tracker.id,
                },
                query: {
                    measurement: combinedChartKeys,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('power0') }}:

                <h3 class="sensor-data-w">
                    {{ power0.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="power1"
            :title="power1.title"
            :datetime="power1.lastUpdated"
            :link-to="{
                name: 'chartsCombined',
                params: {
                    id: tracker.id,
                },
                query: {
                    measurement: combinedChartKeys,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('power1') }}:

                <h3 class="sensor-data-w">
                    {{ power1.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="power2"
            :title="power2.title"
            :datetime="power2.lastUpdated"
            :link-to="{
                name: 'chartsCombined',
                params: {
                    id: tracker.id,
                },
                query: {
                    measurement: combinedChartKeys,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('power2') }}:

                <h3 class="sensor-data-w">
                    {{ power2.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="power3"
            :title="power3.title"
            :datetime="power3.lastUpdated"
            :link-to="{
                name: 'chartsCombined',
                params: {
                    id: tracker.id,
                },
                query: {
                    measurement: combinedChartKeys,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('power3') }}:

                <h3 class="sensor-data-w">
                    {{ power3.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="temperature.link"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import FlashIcon from './icons/FlashIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'SolarPowerMeasurement',
    components: {
        FlashIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        combinedChartKeys() {
            return [this.power0, this.power1, this.power2, this.power3]
                .filter(Boolean)
                .map(item => item.key)
        },
        power() {
            return this.getMeasurementItem('power')
        },
        power0() {
            return this.getMeasurementItem('power_0')
        },
        power1() {
            return this.getMeasurementItem('power_1')
        },
        power2() {
            return this.getMeasurementItem('power_2')
        },
        power3() {
            return this.getMeasurementItem('power_3')
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>

<i18n>
{
    "en": {
        "power": "Total",
        "power0": "L0",
        "power1": "L1",
        "power2": "L2",
        "power3": "L3"
    },
    "de": {
        "power": "Total",
        "power0": "L0",
        "power1": "L1",
        "power2": "L2",
        "power3": "L3"
    },
    "fr": {
        "power": "Total",
        "power0": "L0",
        "power1": "L1",
        "power2": "L2",
        "power3": "L3"
    },
    "it": {
        "power": "Totalmente",
        "power0": "L0",
        "power1": "L1",
        "power2": "L2",
        "power3": "L3"
    }
}
</i18n>

<style lang="scss" scoped>
p {
    font-size: $fs-small;
}
</style>
