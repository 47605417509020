var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.t1)?_c('MeasurementItem',{attrs:{"title":_vm.t1.title,"datetime":_vm.t1.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('t1'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.t1.value)+" ")])]},proxy:true}],null,false,13612798)}):_vm._e(),(_vm.temperature)?_c('MeasurementItem',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,239159870)}):_vm._e(),(_vm.humidity)?_c('MeasurementItem',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,1596388769)}):_vm._e(),(_vm.humidityAbsolute)?_c('MeasurementItem',{attrs:{"title":_vm.humidityAbsolute.title,"datetime":_vm.humidityAbsolute.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('humidity_absolute'))+": "),_c('h3',{staticClass:"sensor-data-gpm3"},[_vm._v(" "+_vm._s(_vm.humidityAbsolute.value)+" ")])]},proxy:true}],null,false,3767586703)}):_vm._e(),(_vm.externalHumidity)?_c('MeasurementItem',{attrs:{"title":_vm.externalHumidity.title,"datetime":_vm.externalHumidity.lastUpdated,"link-to":{
            name: 'cm2humiditychart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('externalHumidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.externalHumidity.value)+" ")])]},proxy:true}],null,false,3421357921)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }