<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="0.75" y1="0.75" x2="0.75" y2="23.25" />

        <line class="a" x1="0.75" y1="9" x2="5.643" y2="5.738" />

        <line class="a" x1="0.75" y1="10.5" x2="5.644" y2="13.762" />

        <path
            class="a"
            d="M23.25,9.75a4.5,4.5,0,0,1-4.5,4.5h-12a1.5,1.5,0,0,1-1.5-1.5v-6a1.5,1.5,0,0,1,1.5-1.5h12A4.5,4.5,0,0,1,23.25,9.75Z"
        />

        <line class="a" x1="9.75" y1="5.25" x2="9.75" y2="14.25" />

        <line class="a" x1="14.25" y1="5.25" x2="14.25" y2="14.25" />

        <line class="a" x1="18.75" y1="5.25" x2="18.75" y2="14.25" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'WindIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
