var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.precipitationAbsolute)?_c('MeasurementItem',{attrs:{"title":_vm.precipitationAbsolute.title,"datetime":_vm.precipitationAbsolute.lastUpdated,"link-to":_vm.precipitationIntensity
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [
                              _vm.precipitationAbsolute.key,
                              _vm.precipitationIntensity.key ],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.precipitationAbsolute.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.precipitation_absolute'))+": "),_c('h3',{staticClass:"sensor-data-distance-mm"},[_vm._v(" "+_vm._s(_vm.precipitationAbsolute.value)+" ")])]},proxy:true}],null,false,2043393624)}):_vm._e(),(_vm.precipitationIntensity)?_c('MeasurementItem',{attrs:{"title":_vm.precipitationIntensity.title,"datetime":_vm.precipitationIntensity.lastUpdated,"link-to":_vm.precipitationAbsolute
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [
                              _vm.precipitationAbsolute.key,
                              _vm.precipitationIntensity.key ],
                      },
                  }
                : {
                      name: 'charts',
                      params: {
                          id: _vm.tracker.id,
                          dataType: _vm.precipitationIntensity.key,
                      },
                  }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.precipitation_intensity'))+": "),_c('h3',{staticClass:"sensor-data-speed-mm"},[_vm._v(" "+_vm._s(_vm.precipitationIntensity.value)+" ")])]},proxy:true}],null,false,3916784796)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }