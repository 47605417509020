var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.state)?_c('MeasurementItem',{attrs:{"title":_vm.state.title,"datetime":_vm.state.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: 'd1',
                labelPair: 'operation',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.state.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.state.value ? 'running' : 'notRunning'))+" ")])]},proxy:true}],null,false,1792578258)}):_vm._e(),(_vm.speed)?_c('MeasurementItem',{attrs:{"title":_vm.speed.title,"datetime":_vm.speed.lastUpdated,"link-to":_vm.speed.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('SpeedIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.speed'))+": "),_c('h3',{staticClass:"sensor-data-speed"},[_vm._v(" "+_vm._s(_vm.speed.value)+" ")])]},proxy:true}],null,false,4106886752)}):_vm._e(),_c('BatteryMeasurementsFragment',_vm._b({},'BatteryMeasurementsFragment',_vm.$props,false)),(_vm.externalVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.externalVoltage.title,"datetime":_vm.externalVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.externalVoltage.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.external_voltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.externalVoltage.value)+" ")])]},proxy:true}],null,false,2797766989)}):_vm._e(),(_vm.odometer)?_c('MeasurementItem',{attrs:{"title":_vm.odometer.title,"datetime":_vm.odometer.lastUpdated,"link-to":_vm.odometer.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DistanceIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.odometer'))+": "),_c('h3',{staticClass:"sensor-data-odometer"},[_vm._v(" "+_vm._s(_vm.odometer.value)+" ")])]},proxy:true}],null,false,3190551436)}):_vm._e(),(_vm.hasRunningTimeAccess && _vm.runningTime)?_c('MeasurementItem',{attrs:{"title":_vm.runningTime.title,"datetime":_vm.runningTime.lastUpdated,"link-to":{
            name: 'runningTimeChart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('StopwatchIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('runningTime'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.runningTime.textDuration)+" ")])]},proxy:true}],null,false,40199177)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }