<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <line class="a" x1="7.5" y1="11.25" x2="15" y2="18.75" />

        <line class="a" x1="15" y1="11.25" x2="7.5" y2="18.75" />

        <path
            class="a"
            d="M19.5,21A1.5,1.5,0,0,1,18,22.5H4.5A1.5,1.5,0,0,1,3,21V6A1.5,1.5,0,0,1,4.5,4.5h9.75L19.5,9.75Z"
        />

        <path
            class="a"
            d="M4.5,4.5V3A1.5,1.5,0,0,1,6,1.5h4.5A1.5,1.5,0,0,1,12,3V4.5"
        />

        <path
            class="a"
            d="M15,5.25l1.189-1.189a1.5,1.5,0,0,1,2.122,0l1.628,1.628a1.5,1.5,0,0,1,0,2.122L18.75,9"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'FuelCanIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
