<template>
    <MeasurementItem
        :title="measurement.title"
        :datetime="measurement.lastUpdated"
        :link-to="measurement.link"
    >
        <template #icon>
            <FilledCircleIcon
                width="24"
                height="24"
                :class="measurement.value ? 'positive' : 'negative'"
            />
        </template>

        <template #content>
            <h3>{{ measurement.label }}</h3>
        </template>
    </MeasurementItem>
</template>

<script>
import FilledCircleIcon from './icons/FilledCircleIcon'
import MeasurementItem from './MeasurementItem'

export default {
    name: 'BooleanStatusMeasurementItem',
    components: {
        FilledCircleIcon,
        MeasurementItem,
    },
    props: {
        measurement: {
            type: Object,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
h3 {
    padding: 0;
}

.positive {
    color: $color-green;
}

.negative {
    color: $color-red;
}
</style>
