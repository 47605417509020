<template>
    <div>
        <MeasurementItem
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: battery.key,
                },
            }"
        >
            <template #icon>
                <BatteryIcon
                    :battery-level-in-percent="battery.value"
                    :warn="false"
                    width="24"
                    height="24"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="analogInput"
            :title="analogInput.title"
            :datetime="analogInput.lastUpdated"
            :link-to="{
                name: 'chartsCombined',
                params: {
                    id: tracker.id,
                },
                query: {
                    assetType: 'cluey-leakage',
                    measurement: analogInput.key,
                },
            }"
        >
            <template #icon>
                <FlashIcon width="24" height="24" />
            </template>

            <template #content>
                <div class="measurement-value-range">
                    <div
                        v-for="(measurement, i) in analogInput.key"
                        :key="i"
                        class="l-inline l-center-v l-spread"
                    >
                        <span class="t-small">
                            {{ $t('sensor') }} {{ i + 1 }}
                        </span>

                        <strong class="sensor-data-moser">
                            {{ analogInput.value[i] }}
                        </strong>
                    </div>
                </div>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BatteryIcon from './icons/BatteryIcon'
import FlashIcon from './icons/FlashIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'ClueyLeakageMeasurement',
    components: {
        BatteryIcon,
        FlashIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        analogInput() {
            const keys = ['ai2', 'ai3', 'ai4', 'ai5', 'ai6', 'ai7', 'ai8']
            const data = keys
                .map(key => this.tracker.asset_details.sensor_data[key])
                .filter(Boolean)

            if (!data.length) {
                return null
            }

            const lastUpdated = data[0].last_update
            return {
                key: keys,
                title:
                    lastUpdated &&
                    this.getItemTitle(keys.join(' | '), lastUpdated),
                value: data.map(data => data.value),
                lastUpdated,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "sensor": "Sensor"
    },
    "de": {
        "sensor": "Sensor"
    },
    "fr": {
        "sensor": "Sensor"
    },
    "it": {
        "sensor": "Sensor"
    }
}
</i18n>

<style lang="scss" scoped>
.measurement-value-range {
    display: flex;
    flex-direction: column;
    padding: 0 16px 4px 0;
    width: 100%;

    & > * {
        padding: 2px 0;
        border-bottom: 2px solid $color-danger;
        line-height: 20px;
    }
}
</style>
