<template>
    <div>
        <MeasurementItem
            v-if="fillLevel"
            :title="fillLevel.title"
            :datetime="fillLevel.lastUpdated"
            :link-to="fillLevel.link"
        >
            <template #icon>
                <BinFillIcon
                    width="24"
                    height="24"
                    :fullness="fillLevel.value"
                    :fill-color="colorsMap[fillLevel.level]"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.fill_level') }}:

                <h3 class="sensor-data-percentage">
                    {{ fillLevel.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="battery"
            :title="battery.title"
            :datetime="battery.lastUpdated"
            :link-to="battery.link"
        >
            <template #icon>
                <BatteryIcon
                    width="24"
                    height="24"
                    :battery-level-in-percent="battery.value"
                />
            </template>

            <template #content>
                {{ $t('shared.measurements.battery') }}:

                <h3 class="sensor-data-percentage">
                    {{ battery.value }}
                </h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BatteryIcon from '../../icons/BatteryIcon'
import BinFillIcon from '../../icons/BinFillIcon'
import MeasurementItem from '../../MeasurementItem'
import MeasurementListHelper from '../../../mixins/MeasurementListHelper'
import { fillLevelThresholds } from './dynamicBinSettings'

export default {
    name: 'DynamicBinMeasurement',
    components: {
        BatteryIcon,
        BinFillIcon,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            colorsMap: {
                low: '#41b883',
                medium: '#fdae61',
                high: '#d32f2f',
            },
        }
    },
    computed: {
        battery() {
            return this.getMeasurementItem('battery')
        },
        fillLevel() {
            const measurement = 'fill_level'
            const data = this.getMeasurementItem(measurement)

            if (!data) {
                return null
            }

            const value = parseInt(data.value * 100)

            const level =
                data.value <= fillLevelThresholds.low
                    ? 'low'
                    : data.value >= fillLevelThresholds.high
                    ? 'high'
                    : 'medium'

            return { ...data, value, level }
        },
    },
}
</script>
