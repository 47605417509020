var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.externalVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.externalVoltage.title,"datetime":_vm.externalVoltage.lastUpdated,"link-to":_vm.externalVoltage.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.external_voltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.externalVoltage.value)+" ")])]},proxy:true}],null,false,2797766989)}):_vm._e(),(_vm.odometer)?_c('MeasurementItem',{attrs:{"title":_vm.odometer.title,"datetime":_vm.odometer.lastUpdated,"link-to":_vm.odometer.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DistanceIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.odometer'))+": "),_c('h3',{staticClass:"sensor-data-odometer"},[_vm._v(" "+_vm._s(_vm.odometer.value)+" ")])]},proxy:true}],null,false,3190551436)}):_vm._e(),(_vm.fuelLevel)?_c('MeasurementItem',{attrs:{"title":_vm.fuelLevel.title,"datetime":_vm.fuelLevel.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.fuelLevel.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FuelCanIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.fuel_level'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.fuelLevel.value)+" ")])]},proxy:true}],null,false,1373389265)}):_vm._e(),(_vm.evBatteryPercentage)?_c('MeasurementItem',{attrs:{"title":_vm.evBatteryPercentage.title,"datetime":_vm.evBatteryPercentage.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.evBatteryPercentage.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.evBatteryPercentage.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.ev_battery_percentage'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.evBatteryPercentage.value)+" ")])]},proxy:true}],null,false,3106717706)}):_vm._e(),(_vm.evBatteryHealth)?_c('MeasurementItem',{attrs:{"title":_vm.evBatteryHealth.title,"datetime":_vm.evBatteryHealth.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.evBatteryHealth.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":_vm.evBatteryHealth.value}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.ev_battery_health'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.evBatteryHealth.value)+" ")])]},proxy:true}],null,false,1573870254)}):_vm._e(),(_vm.evChargingState)?_c('MeasurementItem',{attrs:{"title":_vm.evChargingState.title,"datetime":_vm.evChargingState.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.evChargingState.key,
                labelPair: 'operation',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.evChargingState.value ? 'green' : 'red',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"measurement-value"},[_vm._v(" "+_vm._s(_vm.evChargingState.value ? _vm.$t('charging') : _vm.$t('chargingNot'))+" ")])]},proxy:true}],null,false,4276825732)}):_vm._e(),(_vm.fuelRate)?_c('MeasurementItem',{attrs:{"title":_vm.fuelRate.title,"datetime":_vm.fuelRate.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.fuelRate.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FuelCanIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.fuel_rate'))+": "),_c('h3',{class:_vm.speed && _vm.speed.value
                        ? 'sensor-data-lp100km'
                        : 'sensor-data-lph'},[_vm._v(" "+_vm._s(_vm.fuelRate.value)+" ")])]},proxy:true}],null,false,1182955912)}):_vm._e(),_c('BatteryMeasurementsFragment',_vm._b({},'BatteryMeasurementsFragment',_vm.$props,false)),(_vm.dtcCodes)?_c('MeasurementItem',{attrs:{"title":_vm.dtcCodes.title,"datetime":_vm.dtcCodes.lastUpdated},on:{"click":function($event){_vm.isErrorBlockVisible = !_vm.isErrorBlockVisible}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('WarningIcon',{attrs:{"width":"24","height":"24","color":"black"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('errors'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.dtcCodes.value)+" ")])]},proxy:true},{key:"actionicon",fn:function(){return [_c('ArrowDownIcon',{staticClass:"arrow-icon",class:{ 'arrow-icon--rotated': _vm.isErrorBlockVisible },attrs:{"width":"12","height":"12"}})]},proxy:true}],null,false,1867130651)}):_vm._e(),(_vm.isErrorBlockVisible)?_c('div',{staticClass:"l-padded"},_vm._l((_vm.dtcCodeList),function(item,i){return _c('p',{key:i},[_c('strong',[_vm._v(_vm._s(item.code)+".")]),_vm._v(" "+_vm._s(item.description)+". ")])}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }