var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.state)?_c('MeasurementItem',{attrs:{"title":_vm.state.title,"datetime":_vm.state.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.state.key,
                labelPair: 'alert',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.state.value ? 'negative' : 'positive',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.$t(_vm.state.value ? 'negative' : 'positive')))])]},proxy:true}],null,false,793415906)}):_vm._e(),_c('BatteryMeasurementsFragment',_vm._b({},'BatteryMeasurementsFragment',_vm.$props,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }