<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M18.75,15.449a.375.375,0,1,1-.375.375.375.375,0,0,1,.375-.375"
        />

        <path
            class="a"
            d="M18.75,11.324a4.5,4.5,0,0,1,4.5,4.5c0,1.921-2.688,5.577-3.909,7.138a.75.75,0,0,1-1.182,0c-1.221-1.562-3.909-5.217-3.909-7.138A4.5,4.5,0,0,1,18.75,11.324Z"
        />

        <path class="a" d="M14.248,23.032a11.255,11.255,0,1,1,8.769-13.32" />

        <path
            class="a"
            d="M9.289,22.921C7.767,20.689,6.75,16.633,6.75,12S7.767,3.312,9.289,1.079"
        />

        <line class="a" x1="0.775" y1="11.25" x2="12.75" y2="11.25" />

        <line class="a" x1="2.999" y1="5.25" x2="21" y2="5.25" />

        <line class="a" x1="2.048" y1="17.25" x2="11.25" y2="17.25" />

        <path class="a" d="M14.711,1.079A17.039,17.039,0,0,1,17.02,8.254" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'NetworkPinIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
