import moment from 'moment-timezone'

export default {
    props: {
        isShared: {
            tyep: Boolean,
            default: false,
        },
        tracker: {
            type: Object,
            required: true,
        },
    },
    computed: {
        sensorData() {
            return (
                this.tracker.asset_details?.sensor_data ??
                this.tracker.sensor_data
            )
        },
    },
    methods: {
        getItemTitle(measurement, lastUpdate) {
            const date = moment(lastUpdate).format('DD.MM.YYYY HH:mm')
            const translationKey = `shared.measurements.${measurement}`
            const key = this.$root.$te(translationKey)
                ? this.$t(translationKey)
                : measurement

            return `${key} | ${this.$t('shared.lastMeasurement')}: ${date}`
        },
        getMeasurementItem(measurement) {
            const data = this.sensorData[measurement]

            if (!data) {
                return null
            }

            return {
                key: measurement,
                title:
                    data.last_update &&
                    this.getItemTitle(measurement, data.last_update),
                value: data.value,
                lastUpdated: data.last_update,
                link: this.tracker.asset_details
                    ? {
                          name: 'charts',
                          params: {
                              id: this.tracker.id,
                              dataType: measurement,
                          },
                      }
                    : null,
            }
        },
    },
}
