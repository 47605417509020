<template>
    <div>
        <CM2HumidityMeasurement v-bind="$props" />

        <MeasurementItem
            v-if="brightness"
            :title="brightness.title"
            :datetime="brightness.lastUpdated"
            :link-to="{
                name: 'charts',
                params: {
                    id: tracker.id,
                    dataType: brightness.key,
                },
            }"
        >
            <template #icon>
                <BrightnessIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.brightness') }}:

                <h3>{{ brightness.value }}</h3>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import BrightnessIcon from './icons/BrightnessIcon'
import CM2HumidityMeasurement from './CM2HumidityMeasurement'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

export default {
    name: 'CM2BrigthnessMeasurement',
    components: {
        BrightnessIcon,
        CM2HumidityMeasurement,
        MeasurementItem,
    },
    mixins: [MeasurementListHelper],
    computed: {
        brightness() {
            return this.getMeasurementItem('brightness')
        },
    },
}
</script>
