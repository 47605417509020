<template>
    <div>
        <TemperatureMoistureMeasurementFragment v-bind="$props" />

        <MeasurementItem
            v-if="moistureAverage"
            :title="moistureAverage.title"
            :datetime="moistureAverage.lastUpdated"
            :link-to="moistureAverage.link"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                ⌀ {{ $t('shared.measurements.moisture') }}:

                <h3 class="sensor-data-percentage">
                    {{ moistureAverage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="temperatureAverage"
            :title="temperatureAverage.title"
            :datetime="temperatureAverage.lastUpdated"
            :link-to="temperatureAverage.link"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                ⌀ {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperatureAverage.value }}
                </h3>
            </template>
        </MeasurementItem>

        <BatteryMeasurementsFragment v-bind="$props" />
    </div>
</template>

<script>
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import TemperatureMoistureMeasurementFragment from './TemperatureMoistureMeasurementFragment'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'MoistureMeasurements',
    components: {
        BatteryMeasurementsFragment,
        DropIcon,
        MeasurementItem,
        TemperatureMoistureMeasurementFragment,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        moistureAverage() {
            return this.getMeasurementItem('moisture')
        },
        temperatureAverage() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>
