export const sbbFillLevelThresholds = {
    normal: {
        high: 75,
        low: 40,
    },
    paper: {
        high: 35,
        low: 20,
    },
}
