var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MeasurementItem',{scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.isLoading)?_c('div',{staticClass:"no-padding l-full-width"},[_c('VSpinner',{attrs:{"size":"medium","line-fg-color":"black","speed":1}})],1):_c('div',{staticClass:"no-padding"},[_c('h3',[_vm._v(_vm._s(_vm.$t('title')))]),_c('p',{staticClass:"l-inline l-gap-2 l-center-v"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('currentShift'))+": "),_c('b',[_vm._v(_vm._s(_vm.currentShift))])]),(_vm.remainingDrivingTimes)?_c('ProgressDots',{attrs:{"value":_vm.remainingDrivingTimes.value,"max":2,"title":_vm.$tc(
                            'remainingDrivingTimes',
                            _vm.remainingDrivingTimes
                                ? _vm.remainingDrivingTimes.value
                                : 0
                        )}}):_vm._e()],1),_c('p',[_vm._v(" "+_vm._s(_vm.$t('pause'))+": "),_c('b',[_vm._v(_vm._s(_vm.totalBreak))])]),_c('p',{staticClass:"l-inline l-gap-2 l-center-v"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('timeUntilRest'))+": "),_c('b',[_vm._v(_vm._s(_vm.timeUntilRest))])]),(_vm.remainingRestPeriods)?_c('ProgressDots',{attrs:{"value":_vm.remainingRestPeriods.value,"max":3,"title":_vm.$tc(
                            'remainingRestPeriods',
                            _vm.remainingRestPeriods
                                ? _vm.remainingRestPeriods.value
                                : 0
                        )}}):_vm._e()],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }