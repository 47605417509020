var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.d1)?_c('MeasurementItem',{attrs:{"title":_vm.d1.title,"datetime":_vm.d1.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d1.key,
                labelPair: 'malfunction',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d1.value ? 'negative' : 'positive',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.d1.value ? 'd1NotOK' : 'd1OK'))+" ")])]},proxy:true}],null,false,4091286258)}):_vm._e(),(_vm.d2)?_c('MeasurementItem',{attrs:{"title":_vm.d2.title,"datetime":_vm.d2.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d2.key,
                labelPair: 'maintenance',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d2.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.d2.value ? 'd2OK' : 'd2NotOK'))+" ")])]},proxy:true}],null,false,4187515826)}):_vm._e(),(_vm.d3)?_c('MeasurementItem',{attrs:{"title":_vm.d3.title,"datetime":_vm.d3.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d3.key,
                labelPair: 'door',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d3.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.d3.value ? 'd3NotOK' : 'd3OK'))+" ")])]},proxy:true}],null,false,3234520498)}):_vm._e(),(_vm.d4)?_c('MeasurementItem',{attrs:{"title":_vm.d4.title,"datetime":_vm.d4.lastUpdated,"link-to":{
            name: 'statuschartview',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.d4.key,
                labelPair: 'flood',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FilledCircleIcon',{class:_vm.d4.value ? 'positive' : 'negative',attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_c('h3',{staticClass:"no-padding-left"},[_vm._v(" "+_vm._s(_vm.$t(_vm.d4.value ? 'd4OK' : 'd4NotOK'))+" ")])]},proxy:true}],null,false,294129586)}):_vm._e(),(_vm.t1)?_c('MeasurementItem',{attrs:{"title":_vm.t1.title,"datetime":_vm.t1.lastUpdated,"link-to":_vm.t2 && _vm.t3 && _vm.t4
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.t1.key, _vm.t2.key, _vm.t3.key, _vm.t4.key],
                      },
                  }
                : _vm.t1.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('t1'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.t1.value)+" ")])]},proxy:true}],null,false,13612798)}):_vm._e(),(_vm.t2)?_c('MeasurementItem',{attrs:{"title":_vm.t2.title,"datetime":_vm.t2.lastUpdated,"link-to":_vm.t1 && _vm.t3 && _vm.t4
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.t1.key, _vm.t2.key, _vm.t3.key, _vm.t4.key],
                      },
                  }
                : _vm.t2.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('t2'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.t2.value)+" ")])]},proxy:true}],null,false,2489400670)}):_vm._e(),(_vm.t3)?_c('MeasurementItem',{attrs:{"title":_vm.t3.title,"datetime":_vm.t3.lastUpdated,"link-to":_vm.t1 && _vm.t2 && _vm.t4
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.t1.key, _vm.t2.key, _vm.t3.key, _vm.t4.key],
                      },
                  }
                : _vm.t3.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('t3'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.t3.value)+" ")])]},proxy:true}],null,false,4138017598)}):_vm._e(),(_vm.t4)?_c('MeasurementItem',{attrs:{"title":_vm.t4.title,"datetime":_vm.t4.lastUpdated,"link-to":_vm.t1 && _vm.t2 && _vm.t3
                ? {
                      name: 'chartsCombined',
                      params: {
                          id: _vm.tracker.id,
                      },
                      query: {
                          measurement: [_vm.t1.key, _vm.t2.key, _vm.t3.key, _vm.t4.key],
                      },
                  }
                : _vm.t4.link},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('t4'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.t4.value)+" ")])]},proxy:true}],null,false,2697735326)}):_vm._e(),(_vm.hasRunningTimeAccess && _vm.runningTime)?_c('MeasurementItem',{attrs:{"title":_vm.runningTime.title,"datetime":_vm.runningTime.lastUpdated,"link-to":{
            name: 'runningTimeChart',
            params: { id: _vm.tracker.id },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('StopwatchIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('runningTime'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.runningTime.textDuration)+" ")])]},proxy:true}],null,false,40199177)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }