<template>
    <div>
        <MeasurementItem
            v-if="temperature"
            :title="temperature.title"
            :datetime="temperature.lastUpdated"
            :link-to="combinedLink"
        >
            <template #icon>
                <ThermometerIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.temperature') }}:

                <h3 class="sensor-data-temperature">
                    {{ temperature.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidity"
            :title="humidity.title"
            :datetime="humidity.lastUpdated"
            :link-to="combinedLink"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.humidity') }}:

                <h3 class="sensor-data-percentage">
                    {{ humidity.value }}
                </h3>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="humidityAbsolute"
            :title="humidityAbsolute.title"
            :datetime="humidityAbsolute.lastUpdated"
            :link-to="combinedLink"
        >
            <template #icon>
                <DropIcon width="24" height="24" />
            </template>

            <template #content>
                {{ $t('shared.measurements.humidity_absolute') }}:

                <h3 class="sensor-data-gpm3">
                    {{ humidityAbsolute.value }}
                </h3>
            </template>
        </MeasurementItem>

        <BatteryMeasurementsFragment v-bind="$props" />
    </div>
</template>

<script>
import { measurementHelper } from '@/utils'
import BatteryMeasurementsFragment from './BatteryMeasurementsFragment'
import DropIcon from './icons/DropIcon'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

export default {
    name: 'CM1Measurement',
    components: {
        BatteryMeasurementsFragment,
        DropIcon,
        MeasurementItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        combinedLink() {
            return {
                name: 'chartsCombined',
                params: {
                    id: this.tracker.id,
                },
                query: {
                    measurement: [
                        this.humidity.key,
                        this.humidityAbsolute.key,
                        this.temperature.key,
                    ],
                },
            }
        },
        humidity() {
            return this.getMeasurementItem('humidity')
        },
        humidityAbsolute() {
            if (!this.humidity || !this.temperature) {
                return null
            }

            const lastUpdated = [
                this.humidity.lastUpdated,
                this.temperature.lastUpdated,
            ].sort()[1]

            return {
                key: 'humidity_absolute',
                title: this.getItemTitle(
                    this.$t('shared.measurements.humidity_absolute'),
                    lastUpdated
                ),
                value: measurementHelper.calculateAbsoluteHumidity(
                    this.humidity.value,
                    this.temperature.value
                ),
                lastUpdated,
                link: {
                    name: 'charts',
                    params: {
                        id: this.tracker.id,
                        dataType: 'humidity_absolute',
                    },
                },
            }
        },
        temperature() {
            return this.getMeasurementItem('temperature')
        },
    },
}
</script>
