<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="''"
        :fill-color="color"
        @click="$emit('click')"
    >
        <path
            d="M12.5,2.5H20a1,1,0,0,1,1,1v19a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V3.5a1,1,0,0,1,1-1H5.5"
        />
        <path
            d="M11,.5,5.089,7.882a.376.376,0,0,0,.286.618H8v6l5.911-7.382a.376.376,0,0,0-.286-.618H11Z"
        />
        <line x1="17" y1="10" x2="17" y2="20.5" />
        <line x1="14" y1="12.5" x2="14" y2="20.5" />
        <line x1="11" y1="15.5" x2="11" y2="20.5" />
        <line x1="8" y1="18" x2="8" y2="20.5" />
        <line x1="6" y1="20.5" x2="18" y2="20.5" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'EnergyIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
line,
path {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
