<template>
    <div class="u-bb">
        <div class="l-padded wrapper">
            <div class="l-inline l-justify-end l-center-v l-gap-0">
                <span>{{ $t('shared.measurements.temperature') }}</span>
                <ThermometerIcon />
            </div>

            <div class="l-inline l-center-v l-gap-0">
                <DropIcon />
                <span>{{ $t('shared.measurements.moisture') }}</span>
            </div>

            <template v-for="(data, i) in entries">
                <div :key="'t' + data.level">
                    <div class="l-inline l-spread">
                        <div class="t-small t-subtle">
                            <template v-if="i === 0">
                                {{ $t('top') }}
                            </template>

                            <template v-else-if="i === entries.length - 1">
                                {{ $t('bottom') }}
                            </template>
                        </div>

                        <div>{{ data.temperatureFormatted }}</div>
                    </div>

                    <div :style="data.temperatureStyles" />
                </div>

                <div :key="'m' + data.level">
                    <div>{{ data.moistureFormatted }}</div>

                    <div :style="data.moistureStyles" />
                </div>
            </template>
        </div>

        <template v-if="!isShared">
            <router-link :to="{ name: 'treehistorytemperaturechart' }">
                <ListItem>
                    <div class="l-inline l-center-v l-gap-2">
                        <BarChartIcon />
                        <span>{{ $t('temperatureLink') }}</span>
                    </div>

                    <ArrowRightIcon />
                </ListItem>
            </router-link>

            <router-link :to="{ name: 'treehistorymoisturechart' }">
                <ListItem>
                    <div class="l-inline l-center-v l-gap-2">
                        <BarChartIcon />
                        <span>{{ $t('moistureLink') }}</span>
                    </div>

                    <ArrowRightIcon />
                </ListItem>
            </router-link>
        </template>
    </div>
</template>

<script>
import { measurementHelper } from '../utils'
import ArrowRightIcon from './icons/ArrowRightIcon'
import BarChartIcon from './icons/BarChartIcon'
import DropIcon from './icons/DropIcon'
import ListItem from './ListItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import ThermometerIcon from './icons/ThermometerIcon'

const { VUE_APP_COLOR_ACCENT: colorAccent } = process.env

export default {
    name: 'TemperatureMoistureMeasurementFragment',
    components: {
        ArrowRightIcon,
        BarChartIcon,
        DropIcon,
        ListItem,
        ThermometerIcon,
    },
    mixins: [MeasurementListHelper],
    computed: {
        entries() {
            return Object.keys(this.temperatures).map(key => {
                const temperature = this.temperatures[key].value
                const moisture = this.moistures[key].value

                return {
                    level: +key + 1,
                    moisture,
                    moistureFormatted: `${moisture.toFixed(1)} ${
                        measurementHelper.units.moisture
                    }`,
                    moistureStyles: this.getMoistureStyles(moisture),
                    temperature,
                    temperatureFormatted: `${temperature.toFixed(1)} ${
                        measurementHelper.units.temperature
                    }`,
                    temperatureStyles: this.getTemperatureStyles(temperature),
                }
            })
        },
        moistures() {
            return Object.fromEntries(
                Object.entries(this.tracker?.asset_details?.sensor_data || {})
                    .filter(([key]) => key.indexOf('moisture_') === 0)
                    .map(([key, value]) => [
                        key.replace('moisture_', ''),
                        value,
                    ])
            )
        },
        temperatures() {
            return Object.fromEntries(
                Object.entries(this.tracker?.asset_details?.sensor_data || {})
                    .filter(([key]) => key.indexOf('temperature_') === 0)
                    .map(([key, value]) => [
                        key.replace('temperature_', ''),
                        value,
                    ])
            )
        },
    },
    methods: {
        getMoistureStyles(moisture) {
            const [min, max] = [0, 100]
            const value = ((moisture - min) * 100) / (max - min)

            return {
                width: '100%',
                height: '4px',
                background: `linear-gradient(90deg, #0077c2 ${value}%, rgba(0,0,0,0.08) ${value}%)`,
            }
        },
        getTemperatureStyles(temperature) {
            const [min, max] = [-10, 40]
            const value = 100 - ((temperature - min) * 100) / (max - min)

            return {
                width: '100%',
                height: '4px',
                background: `linear-gradient(90deg, rgba(0,0,0,0.08) ${value}%, ${colorAccent} ${value}%)`,
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "bottom": "Bottom",
        "moistureLink": "Show historic moisture",
        "temperatureLink": "Show historic temperature",
        "top": "Top"
    },
    "de": {
        "bottom": "Unten",
        "moistureLink": "Historische Feuchtigkeit anzeigen",
        "temperatureLink": "Historische Temperatur anzeigen",
        "top": "Oben"
    },
    "fr": {
        "bottom": "Bas",
        "moistureLink": "Afficher l'historique de l'humidité",
        "temperatureLink": "Afficher l'historique des températures",
        "top": "Haut"
    },
    "it": {
        "bottom": "Sotto",
        "moistureLink": "Mostra l'umidità storica",
        "temperatureLink": "Mostra la temperatura storica",
        "top": "Sopra"
    }
}
</i18n>

<style lang="scss" scoped>
.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px 10px;
}
</style>
