<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M5.5,23.247l4.052-9.454a.751.751,0,0,0-.689-1.046H5.9A.749.749,0,0,1,5.21,11.7L9.8,1.2a.75.75,0,0,1,.687-.45h7.7a.75.75,0,0,1,.585,1.219l-4.05,5.063a.75.75,0,0,0,.586,1.218H18.69a.75.75,0,0,1,.53,1.281Z"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'FlashIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
