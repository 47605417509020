<template>
    <div>
        <MeasurementItem
            v-if="nextCalibration || tachographOdometer || vrn"
            :datetime="tachographOdometer.lastUpdated"
        >
            <template #icon>
                <TachographIcon width="24" height="24" />
            </template>

            <template #content>
                <div class="no-padding">
                    <h3>{{ $t('router.tachograph') }}</h3>

                    <div v-if="nextCalibration">
                        {{ $t('nextCalibration') }}:
                        <b>{{ nextCalibration.value }}</b>
                    </div>

                    <div v-if="vrn">
                        {{ $t('vrn') }}:
                        <b>{{ vrn.value }}</b>
                    </div>

                    <div v-if="tachographOdometer">
                        {{ $t('shared.measurements.odometer') }}:
                        <b class="sensor-data-odometer">
                            {{ tachographOdometer.value }}
                        </b>
                    </div>
                </div>
            </template>
        </MeasurementItem>

        <MeasurementItem
            v-if="workingState && selectedActivityDuration !== null"
        >
            <template #icon>
                <svg
                    width="52"
                    height="52"
                    viewBox="0 0 100 100"
                    class="radial-progress"
                    :style="{
                        '--percentage': selectedActivityPercentage,
                    }"
                >
                    <circle cx="50" cy="50" r="45" />
                    <circle cx="50" cy="50" r="45" pathLength="100" />
                </svg>
            </template>

            <template #content>
                <div
                    class="no-padding l-inline l-center-v l-gap-2 current-stats"
                >
                    <div class="l-stack">
                        <h3>{{ formatTime(selectedActivityDuration) }}</h3>
                        <h3>{{ workingState.value }}</h3>
                    </div>
                </div>
            </template>
        </MeasurementItem>
    </div>
</template>

<script>
import moment from 'moment'

import { measurementHelper } from '../utils'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'
import TachographIcon from './icons/TachographIcon'

export default {
    name: 'TruckTachographMeasurementFragment',
    components: {
        MeasurementItem,
        TachographIcon,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            selectedActivityDuration: null,
            selectedActivityDurationInterval: null,
        }
    },
    computed: {
        nextCalibration() {
            const item = this.getMeasurementItem('next_calibration_datetime')
            if (item) {
                item.value = moment(item.value).format('DD.MM.YYYY')
            }
            return item
        },
        selectedActivityPercentage() {
            const state = this.sensorData.selected_activity
            if (!state || !this.selectedActivityDuration) {
                return 0
            }
            const max = {
                0: 2700,
                3: 16200,
                7: this.sensorData.driver_1_duration_next_break?.value,
            }[state.value]
            return max
                ? Math.min(100 * (this.selectedActivityDuration / max), 100)
                : 0
        },
        tachographOdometer() {
            const item = this.getMeasurementItem('tachograph_odometer')
            if (item) {
                item.value = measurementHelper.converters.odometer(item.value)
            }
            return item
        },
        vrn() {
            return this.getMeasurementItem('vrn')
        },
        workingState() {
            const item = this.getMeasurementItem('selected_activity')
            if (item) {
                const translationKey = `shared.tachograph.working_state.${item.value}`
                item.value = this.$root.$te(translationKey)
                    ? this.$t(translationKey)
                    : item.value
            }
            return item
        },
    },
    watch: {
        tracker: {
            immediate: true,
            handler() {
                clearInterval(this.selectedActivityDurationInterval)
                if (!this.sensorData.selected_activity_start) {
                    this.selectedActivityDuration = null
                    return
                }
                const startDate = new Date(
                    this.sensorData.selected_activity_start.value * 1000
                )
                const updateSelectedActivityDuration = () => {
                    this.selectedActivityDuration =
                        (new Date() - startDate) / 1000
                }
                updateSelectedActivityDuration()
                this.selectedActivityDurationInterval = setInterval(
                    updateSelectedActivityDuration,
                    1000
                )
            },
        },
    },
    beforeDestroy() {
        clearInterval(this.selectedActivityDurationInterval)
    },
    methods: {
        formatTime(seconds = 0) {
            const secondsAbsolute = Math.abs(seconds)
            const hours = Math.floor(secondsAbsolute / 60 / 60)
            const minutes = Math.round(secondsAbsolute / 60) - hours * 60
            return `${seconds < 0 ? '-' : ''}${hours}:${
                minutes < 10 ? '0' : ''
            }${minutes}`
        },
    },
}
</script>

<i18n>
{
    "en": {
        "nextCalibration": "Next calibration",
        "vrn": "VRN"
    },
    "de": {
        "nextCalibration": "Nächste Kalibrierung",
        "vrn": "Kennzeichen"
    },
    "fr": {
        "nextCalibration": "Prochain étalonnage",
        "vrn": "VRN"
    },
    "it": {
        "nextCalibration": "Prossima calibrazione",
        "vrn": "VRN"
    }
}
</i18n>

<style lang="scss" scoped>
.current-stats {
    line-height: 24px;

    h3 {
        font-size: 20px;
    }
}

.radial-progress {
    display: block;
    transform: rotate(-90deg);

    circle {
        fill: none;
        stroke-width: 10;

        &:first-child {
            stroke: $color-gray-light-new;
        }

        &:last-child {
            stroke: $color-primary-light;
            stroke-dasharray: 100;
            stroke-dashoffset: calc(100 - var(--percentage));
        }
    }
}
</style>
