var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.batteryVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.batteryVoltage.title,"datetime":_vm.batteryVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.batteryVoltage.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('BatteryIcon',{attrs:{"width":"24","height":"24","battery-level-in-percent":100}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.battery_voltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage"},[_vm._v(" "+_vm._s(_vm.batteryVoltage.value)+" ")])]},proxy:true}],null,false,64675613)}):_vm._e(),(_vm.fenceVoltage)?_c('MeasurementItem',{attrs:{"title":_vm.fenceVoltage.title,"datetime":_vm.fenceVoltage.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.fenceVoltage.key,
            },
            query: {
                assetType: 'fency-boy',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('FlashIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('fenceVoltage'))+": "),_c('h3',{staticClass:"sensor-data-voltage-k"},[_vm._v(" "+_vm._s(_vm.fenceVoltage.value)+" ")])]},proxy:true}],null,false,1710916324)}):_vm._e(),(_vm.temperature)?_c('MeasurementItem',{attrs:{"title":_vm.temperature.title,"datetime":_vm.temperature.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.temperature.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('ThermometerIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.temperature'))+": "),_c('h3',{staticClass:"sensor-data-temperature"},[_vm._v(" "+_vm._s(_vm.temperature.value)+" ")])]},proxy:true}],null,false,3410695502)}):_vm._e(),(_vm.humidity)?_c('MeasurementItem',{attrs:{"title":_vm.humidity.title,"datetime":_vm.humidity.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.humidity.key,
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('DropIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('shared.measurements.humidity'))+": "),_c('h3',{staticClass:"sensor-data-percentage"},[_vm._v(" "+_vm._s(_vm.humidity.value)+" ")])]},proxy:true}],null,false,3770266769)}):_vm._e(),(_vm.impulses)?_c('MeasurementItem',{attrs:{"title":_vm.impulses.title,"datetime":_vm.impulses.lastUpdated,"link-to":{
            name: 'charts',
            params: {
                id: _vm.tracker.id,
                dataType: _vm.impulses.key,
            },
            query: {
                assetType: 'fency-boy',
            },
        }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('LineChartIcon',{attrs:{"width":"24","height":"24"}})]},proxy:true},{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('impulses'))+": "),_c('h3',[_vm._v(" "+_vm._s(_vm.impulses.value)+" ")])]},proxy:true}],null,false,837447282)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }