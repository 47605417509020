<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        @click="$emit('click')"
    >
        <rect
            class="a"
            x="3.75"
            y="8.25"
            width="16.5"
            height="10.5"
            rx="1.5"
            ry="1.5"
        />

        <polygon
            class="a"
            points="15 23.25 9 23.25 9.75 18.75 14.25 18.75 15 23.25"
        />

        <line class="a" x1="6.75" y1="23.25" x2="17.25" y2="23.25" />

        <path class="a" d="M9.348,4.886a3.752,3.752,0,0,1,5.3,0" />

        <path class="a" d="M7.227,2.727a6.75,6.75,0,0,1,9.546,0" />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'MonitorSignalIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 18,
        },
        height: {
            type: [Number, String],
            default: 18,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
