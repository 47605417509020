<template>
    <Icon
        :name="name"
        :width="width"
        :height="height"
        :color="color"
        viewbox-width="24"
        viewbox-height="24"
        @click="$emit('click')"
    >
        <path
            class="a"
            d="M23.25,15.5c-2.44.494-3.953-1.94-3.953-1.94h0a4.31,4.31,0,0,1-3.543,2.011,3.742,3.742,0,0,1-3.242-2.011h0a5.171,5.171,0,0,1-3.837,2.011,4.5,4.5,0,0,1-3.537-2.011h0S3.156,16.063.75,15.536"
        />

        <path
            class="a"
            d="M23.25,10.254c-2.44.494-3.953-1.94-3.953-1.94h0a4.31,4.31,0,0,1-3.543,2.011,3.742,3.742,0,0,1-3.242-2.011h0a5.171,5.171,0,0,1-3.837,2.011A4.5,4.5,0,0,1,5.131,8.314h0S3.156,10.813.75,10.286"
        />

        <path
            class="a"
            d="M23.25,5V17.75a1.5,1.5,0,0,1-1.5,1.5H2.25a1.5,1.5,0,0,1-1.5-1.5V5"
        />
    </Icon>
</template>

<script>
import Icon from '@/components/icons/Icon'

export default {
    name: 'WaterLevelIcon',
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: '',
        },
        width: {
            type: [Number, String],
            default: 24,
        },
        height: {
            type: [Number, String],
            default: 24,
        },
        color: {
            type: String,
            default: '#000',
        },
    },
}
</script>

<style lang="scss" scoped>
.a {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}
</style>
